import { InlineEdit, ListGroup, ToastContext } from 'fgirot-k2-ui-components';
import InfoMessage from 'src/components/Common/InfoMessage';
import When from 'src/components/Common/When';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import React, { useContext, useEffect, useState } from 'react';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { usePatchInsuranceEvent } from 'src/service/reporting/PatchInsuranceEvent';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InsuranceEventLabel from '../InsuranceEventLabel';
import { groupByInsuranceEvent, initialDates, isChosenDateValid } from '../../../utils';
import ListProperty from '../../../../../Common/ListProperty';
import { usePeriodText } from '../../../../../../util/date/PeriodUtil';

interface MandatoryFieldEventFromDateCheckpointProps {
  checkpoints: InsuranceEventCheckpoint[];
  insuranceEvents: Map<string, InsuranceEvent>;
  employerId: string;
}
const MandatoryFieldEventFromDateCheckpoint: React.FC<MandatoryFieldEventFromDateCheckpointProps> = ({
  employerId,
  checkpoints,
  insuranceEvents,
}) => {
  const { year, month } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { displayToast } = useContext(ToastContext);

  const updateInsuranceEvent = usePatchInsuranceEvent();
  const insuranceEventTypeToCheckpointsMap = groupByInsuranceEvent(checkpoints);
  const containsBackFromSickEvent = checkpoints.some((c) => c.insuranceEventType === 'BACK_FROM_SICK_LEAVE');
  const containsAbsenceEndRegistrationEvent = checkpoints.some((c) =>
      c.insuranceEventType === 'END_REGISTRATION_DUE_TO_LEAVE_OF_ABSENCE' ||
      c.insuranceEventType === 'END_REGISTRATION_DUE_TO_PARENTAL_LEAVE' ||
      c.insuranceEventType === 'END_REGISTRATION_DUE_TO_MILITARY_SERVICE' ||
      c.insuranceEventType === 'END_REGISTRATION_DUE_TO_STUDIES');
  const [inputDates, setInputDates] = useState<{ [insuranceEventId: string]: string }>(() => {
    return initialDates(checkpoints, insuranceEvents);
  });
  useEffect(() => {
    setInputDates(initialDates(checkpoints, insuranceEvents));
  }, [insuranceEvents, checkpoints]);

  return (
    <>
      <ListGroup variant={'inline-edit'}>
        {Array.from(insuranceEventTypeToCheckpointsMap.keys()).map((key, idx) => {
          const insuranceEventTypeCheckpoints = insuranceEventTypeToCheckpointsMap.get(key);
          const firstCheckpoint = insuranceEventTypeCheckpoints[0];
          const insuranceEventId = firstCheckpoint.additionalData.insuranceEventId;
          const cardPeriod = firstCheckpoint.additionalData.employeeCardPeriod;

          const handleChangeDate = (_field: string, value: string) => {
            if (isChosenDateValid(value, cardPeriod)) {
              setInputDates((prevDates) => ({ ...prevDates, [insuranceEventId]: value }));
              setLoading(true);
              Promise.all(
                checkpoints.map((eventType) =>
                  updateInsuranceEvent(employerId, eventType.additionalData.insuranceEventId, value),
                ),
              ).then(() => {
                setLoading(false);
              });
            } else {
              displayToast('error', t('common:error'), t('validating:drawer.error-toast-message'));
              setInputDates((prevDates) => ({ ...prevDates, [insuranceEventId]: '' }));
            }
          };

          return (
            <React.Fragment key={`${key}-${idx}`}>
              <ListProperty
                label={t('verify:drawer.mandatory-field-from-date-relates-to-period-label')}
                value={usePeriodText(cardPeriod)}
              />
              <InlineEdit bold label={<InsuranceEventLabel checkpoints={insuranceEventTypeCheckpoints} />}>
                <UpdateDatePicker
                  fieldKey={'fromDate'}
                  initialYear={year && parseInt(year)}
                  initialMonth={month && parseInt(month) - 1}
                  onBlur={handleChangeDate}
                  fieldValue={inputDates[insuranceEventId] || ''}
                  disabled={loading}
                />
              </InlineEdit>
            </React.Fragment>
          );
        })}
      </ListGroup>
      <div>
        <When condition={containsBackFromSickEvent}>
          <InfoMessage message={t('verify:drawer.mandatory-field-from-date-info-additional-pgls')} />
        </When>
        <InfoMessage message={t('verify:drawer.mandatory-field-from-date-info-max-three-periods')}/>
        <When condition={containsAbsenceEndRegistrationEvent}>
          <InfoMessage message={t('verify:drawer.mandatory-field-from-date-info-leave-of-absence')}/>
        </When>
      </div>
    </>
  );
};

export default MandatoryFieldEventFromDateCheckpoint;
