import { InsuranceProvider } from '../InsuranceProvider';
import { Employee } from '../Employee';
import { PageableResponse } from '../PageableResponse';
import { Insurance } from '../insurance/Insurance';
import { AccountingComponentValue } from '../accountingunit/AccountingComponent';
import { InsuranceTypeMapping } from '../insurancetypemapping/InsuranceTypeMapping';

export interface AccountingItem {
  id: string;
  employee: Employee;
  accountingNumber: string;
  accountingComponents: LedgerAccountingComponent[];
  accountingTypeValue: string;
  amount: number;
  invoiceNumber: string;
  accountingPeriod: string;
  premiumPeriod: string;
  status: AccountingItemStatusEnum;
  insuranceTypeMapping: InsuranceTypeMapping;
  insuranceProvider: InsuranceProvider;
  insurance: Insurance;
}

export interface LedgerAccountingComponent {
  id: string;
  accountingComponentValue: AccountingComponentValue;
  name: string;
}
export interface AccountingItemsPage extends PageableResponse {
  accountingItems: AccountingItem[];
}

export type AccountingItemStatusEnum = 'PRELIMINARY' | 'FINAL';

export const accountingItemStatuses: AccountingItemStatusEnum[] = ['PRELIMINARY', 'FINAL'];
