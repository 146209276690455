import { gql } from '@apollo/client';

export const GET_PGL_OUTCOME = gql`
  query GetPglOutcome($pglId: UUID!) {
    pglOutcome(pglId: $pglId) {
      id
      pglSum
      lockedPglSum
      ruleId
      name
      policyBenefitPackageId
      pglType
      pglWages {
        conversionFactor
        conversionDenominator
        pglSum
        employerWageTypeId
        includedWages {
          id
          wageType
          employerWageTypeId
          payTypeName
          payTypeNumber
          amount
          fromDate
          toDate
          currency
          employeeCardPeriod
        }
      }
      pglBonuses {
        bonusAverageAmount
        bonusAverageType
        employerWageTypeId
        yearlyBonusSums {
          year
          bonusSum
        }
      }
    }
  }
`;
