import { Banner, DrawerNavigation, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DraftChangedTooltip from 'src/components/Common/DraftChangedTooltip';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import When from 'src/components/Common/When';
import { CalculationLock } from 'src/types/employees/CalculationLock';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import './calculation-locks-drawer-tab.scss';

interface CalculationLocksDrawerTabProps {
  calculationLocks: CalculationLock[];
  onDisplayCalculationLockDetailsDrawer: (id: string) => void;
  onDisplayCreateCalculationLockDrawer: () => void;
  isDraft: boolean;
  hasChangesInDraft: boolean;
}

const CalculationLocksDrawerTab: FC<CalculationLocksDrawerTabProps> = ({
  calculationLocks,
  onDisplayCalculationLockDetailsDrawer,
  onDisplayCreateCalculationLockDrawer,
  isDraft,
  hasChangesInDraft,
}) => {
  const { t } = useTranslation();

  return (
    <div className="calculation-locks-drawer-tab">
      <When condition={isDraft}>
        <div className="calculation-locks-drawer-tab__draft-banner">
          <EmployeeInDraftBanner />
        </div>
      </When>
      {calculationLocks?.length === 0 && (
        <Banner
          type="announcement"
          message={t('calculations:calculation-locks-drawer.no-calculation-locks-info-banner')}
        />
      )}
      <ListGroup variant="drawer-navigation">
        <When condition={calculationLocks.length > 0}>
          {calculationLocks.map((calculationLock) => (
            <DrawerNavigation
              key={`CalculationLocksDrawerTab-${calculationLock.id}`}
              label={
                <div className="calculation-locks-drawer-tab__label">
                  <Typography className="calculation-locks-drawer-tab__label--text" variant="body2" bold component="h2">
                    {calculationLock?.policyPglRuleName?.name
                      ? calculationLock?.policyPglRuleName?.name
                      : calculationLock?.kubType}
                  </Typography>
                  {calculationLock?.deleted && (
                    <Pill label={t('calculations:calculation-locks-drawer.deleted-pill')} type="warning" size="small" />
                  )}
                  {calculationLock.isDraft && (
                    <DraftChangedTooltip
                      label={t('calculations:calculation-locks-drawer.tool-tip-draft-info-message')}
                      hasChangesInDraft={hasChangesInDraft}
                    />
                  )}
                </div>
              }
              value={
                usePeriodText(calculationLock?.fromPeriod) +
                ' - ' +
                (calculationLock?.toPeriod
                  ? usePeriodText(calculationLock?.toPeriod)
                  : t('calculations:calculation-locks-drawer.until-further-notice'))
              }
              onClick={() => onDisplayCalculationLockDetailsDrawer(calculationLock.id)}
              data-cy={`drawer-navigation__calculation-lock-${calculationLock.id}`}
            />
          ))}
        </When>
      </ListGroup>
      {isDraft && (
        <ListGroup variant="drawer-navigation">
          <DrawerNavigation
            label={t('calculations:calculation-locks-drawer.add-calculation-lock')}
            onClick={onDisplayCreateCalculationLockDrawer}
          />
        </ListGroup>
      )}
    </div>
  );
};

export default CalculationLocksDrawerTab;
