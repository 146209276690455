import { post } from 'src/http/post';
import { SearchAccountingItemFilters } from 'src/types/ledgerStorage/SearchAccountingItemFilters';
import { SearchAccountingItemsRequest } from 'src/types/ledgerStorage/SearchAccountingItemsRequest';

export const exportAccountingItems = (filters: SearchAccountingItemFilters) => {
  const searchRequest = mapFiltersToRequest(filters);
  return post('/api/exports/accounting-items', searchRequest, { responseType: 'blob' }).then((response) => {
    if (response.status === 200) {
      const url = URL.createObjectURL(response.data as Blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = response.headers['content-disposition'].split('filename=')[1];
      a.click();
      URL.revokeObjectURL(url);
    }
  });
};

const mapFiltersToRequest = (filters: SearchAccountingItemFilters): SearchAccountingItemsRequest => {
  return {
    searchInput: filters.searchInput,
    pageNumber: 0,
    pageSize: 25,
    filters: {
      employerId: filters.employerId,
      accountingPeriodFilter: {
        fromPeriod: filters.accountingPeriod.fromPeriod,
        toPeriod: filters.accountingPeriod.toPeriod,
      },
      insuranceProviderIdsFilter: filters.insuranceProviderIds,
      premiumPeriodFilter: {
        fromPeriod: filters.premiumPeriod.fromPeriod,
        toPeriod: filters.premiumPeriod.toPeriod,
      },
      accountingTypeValueFilter: filters.accountingTypeValue,
      accountingStatusFilter: filters.status,
    },
  };
};
