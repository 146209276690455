import { SortDirection, TableBody, TableHead, TableRow, Td, Th } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { PremiumInvoiceRowSortField } from './PremiumInvoiceRowSortField';
import { PremiumInvoiceRow } from 'src/types/invoice/PremiumInvoiceRow';
import { getPremiumInvoiceTableHeads, translateBenefitType } from '../utils/premiumInvoiceRowUtils';
import { stringOrBlank } from 'src/util/StringUtil';
import { formatSweAmountText, formatSweAmountTextAlwaysWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import { Sort } from 'src/types/common/Sort';
import { useTranslation } from 'react-i18next';

interface PremiumInvoiceRowsTableProps {
  premiumInvoiceRows: PremiumInvoiceRow[];
  sort: Sort;
  applySorting(property: string, direction: SortDirection);
}

const PremiumInvoiceRowsTable: FC<PremiumInvoiceRowsTableProps> = ({ premiumInvoiceRows, sort, applySorting }) => {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleSort = (property: PremiumInvoiceRowSortField) => {
    if (property == sort.property) {
      applySorting(property, sort.direction == 'UP' ? 'DOWN' : 'UP');
    } else {
      applySorting(property, sort.direction);
    }
  };
  const tableHeads = getPremiumInvoiceTableHeads(handleSort);

  const renderPremiumInvoiceRowsTableData = (premiumInvoiceRow: PremiumInvoiceRow, title: string) => {
    switch (title) {
      case t('premiumInvoiceRows:table.head.insurance-provider'):
        return stringOrBlank(premiumInvoiceRow.insuranceProvider?.legalName);
      case t('premiumInvoiceRows:table.head.agreed-product'):
        return stringOrBlank(premiumInvoiceRow.insurance?.agreedProduct?.name);
      case t('premiumInvoiceRows:table.head.insurance-number'):
        return stringOrBlank(premiumInvoiceRow.insurance?.insuranceNumber);
      case t('premiumInvoiceRows:table.head.insurance-type-mapping-name'):
        return stringOrBlank(premiumInvoiceRow.insuranceTypeMapping?.name);
      case t('premiumInvoiceRows:table.head.amount'):
        return stringOrBlank(formatSweAmountTextAlwaysWithTwoDecimals(premiumInvoiceRow.amount));
      case t('premiumInvoiceRows:table.head.salary-tax'):
        return stringOrBlank(formatSweAmountText(premiumInvoiceRow.salaryTax));
      case t('premiumInvoiceRows:table.head.period-start'):
        return stringOrBlank(premiumInvoiceRow?.periodStart);
      case t('premiumInvoiceRows:table.head.period-end'):
        return stringOrBlank(premiumInvoiceRow?.periodEnd);
      case t('premiumInvoiceRows:table.head.agreement-number'):
        return stringOrBlank(premiumInvoiceRow.insurance?.agreementNumber);
      case t('premiumInvoiceRows:table.head.invoice-number'):
        return stringOrBlank(premiumInvoiceRow?.invoiceNumber);
      case t('premiumInvoiceRows:table.head.benefit-type'):
        return stringOrBlank(t(`benefitTypes:${premiumInvoiceRow.insuranceTypeMapping?.benefitType}`));
      case t('premiumInvoiceRows:table.head.contact-benefit-group'):
        return stringOrBlank(premiumInvoiceRow?.contractBenefitGroup);
      case t('premiumInvoiceRows:table.head.contract-benefit-group-name'):
        return stringOrBlank(premiumInvoiceRow?.contractBenefitGroupName);
      case t('premiumInvoiceRows:table.head.due-date'):
        return stringOrBlank(premiumInvoiceRow?.dueDate);
      case t('premiumInvoiceRows:table.head.payment-date'):
        return stringOrBlank(premiumInvoiceRow?.paymentDueDate);
      case t('premiumInvoiceRows:table.head.note'):
        return stringOrBlank(premiumInvoiceRow.note);
      default:
        return null;
    }
  };

  return (
    <TableBase compressed hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns}>
      <TableHead hiddenColumns={hiddenColumns}>
        <TableRow>
          {tableHeads.map((head, index) => (
            <Th
              compressed
              includeSortButtons={head.handleSortDirectionChange != undefined}
              sortDirection={head.fieldName === sort.property ? sort.direction : undefined}
              handleSortDirectionChange={head.handleSortDirectionChange}
              key={`PremiumInvoiceRowsTableHead-${head.title}-${index}`}
              excludeColumn={head.excludeColumn ? head.excludeColumn : null}
            >
              {head.title}
            </Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {premiumInvoiceRows.map((premiumInvoiceRow, index) => (
          <TableRow
            data-testid={`premium-invoice-rows-table-row__${premiumInvoiceRow.id}`}
            key={`PremiumInvoiceRowsTableRow-${premiumInvoiceRow.id}-${index}`}
          >
            {tableHeads
              .filter((head) => !hiddenColumns.includes(head.title))
              .map((head, index) => (
                <Td compressed key={`row-${premiumInvoiceRow.id}-${index}`}>
                  {renderPremiumInvoiceRowsTableData(premiumInvoiceRow, head.title)}
                </Td>
              ))}
          </TableRow>
        ))}
      </TableBody>
    </TableBase>
  );
};

export default PremiumInvoiceRowsTable;
