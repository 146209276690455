import { Icon, Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { PglType } from 'src/types/policy/PglWageType';
import './details-drawer-title.scss';

interface DetailsDrawerTitleProps {
  period: string;
  type: PglType | 'KUB';
  name: string;
  locked: boolean;
}

const DetailsDrawerTitle = ({ period, name, type, locked }: DetailsDrawerTitleProps) => {
  return (
    <>
      <div className="details-drawer-title">
        <Typography variant="h2" bold>
          {name}
        </Typography>
        {locked && <Icon type={'Lock'} />}
      </div>
      <div className="details-drawer-title">
        <Pill type="success" label={period} />
        <Pill type="neutral" label={type} />
      </div>
    </>
  );
};

export default DetailsDrawerTitle;
