import React, { useState } from 'react';
import { getDefaultAccountItemFilters } from './utils/accountingItemUtils';
import { GET_ACCOUNTING_ITEMS } from 'src/graphql/schema/query/ledgerstorage/GetAccountingItems';
import { AccountingItem } from 'src/types/ledgerStorage/AccountingItem';
import AccountingItems from './AccountingItems';
import './accounting-items-tab.scss';
import { SearchAccountingItemFilters } from 'src/types/ledgerStorage/SearchAccountingItemFilters';
import { AccountingItemsTableSortField } from './AccountingItems/AccountingItemsTable/AccountingItemsTableSortField';
import { SortDirection } from 'fgirot-k2-ui-components';
import { CommonSearchInput } from 'src/types/common/CommonSearchInput';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { Paging } from 'src/types/PageableResponse';
const AccountingItemsTab = () => {
  const [searchAccountingItemFilters, setSearchAccountingItemFilters] = useState<SearchAccountingItemFilters>(
    getDefaultAccountItemFilters(),
  );

  const [pageNumber, setPageNumber] = useState(1);
  const queryPageNumber = pageNumber - 1;
  const pageSize = 25;
  const [searchInput, setSearchInput] = useState<string>('');
  const [sorting, setSorting] = useState<{ property?: AccountingItemsTableSortField; direction: SortDirection }>({
    property: 'accountingPeriod',
    direction: 'DOWN',
  });

  const skipQuery = searchAccountingItemFilters?.employerId === null;

  const handleOnPageChange = (nextPageNumber: number) => {
    setPageNumber(nextPageNumber);
  };

  const handleTextSearch = (input: string) => {
    setSearchInput(input);
  };

  const handleSortingChange = (property: AccountingItemsTableSortField, direction: SortDirection) => {
    setSorting({ property, direction });
  };

  const handleApplyFilters = (filters: SearchAccountingItemFilters) => {
    setSearchAccountingItemFilters(filters);
  };

  const request: CommonSearchInput = {
    paging: {
      pageNumber: queryPageNumber,
      pageSize: pageSize,
    },
    sorting: sorting && [
      { property: sorting.property.toString(), direction: sorting.direction === 'UP' ? 'ASC' : 'DESC' },
    ],
    searchInputs: [
      searchInput?.length > 0 && {
        properties: ['invoiceNumber'],
        value: searchInput,
        exactMatch: false,
      },
    ].filter((search) => search && search.value != ''),
    filters: [
      {
        property: 'employerId',
        values: searchAccountingItemFilters?.employerId?.length ? searchAccountingItemFilters.employerId : null,
        condition: 'IN',
      },
      {
        property: 'accountingPeriod',
        values: searchAccountingItemFilters?.accountingPeriod?.fromPeriod,
        condition: 'GE',
      },
      {
        property: 'accountingPeriod',
        values: searchAccountingItemFilters?.accountingPeriod?.toPeriod,
        condition: 'LE',
      },
      {
        property: 'insuranceProviderIds',
        values: searchAccountingItemFilters?.insuranceProviderIds?.length
          ? searchAccountingItemFilters.insuranceProviderIds
          : null,
        condition: 'IN',
      },
      {
        property: 'premiumPeriod',
        values: searchAccountingItemFilters?.premiumPeriod?.fromPeriod,
        condition: 'GE',
      },
      {
        property: 'premiumPeriod',
        values: searchAccountingItemFilters?.premiumPeriod?.toPeriod,
        condition: 'LE',
      },
      {
        property: 'status',
        values: searchAccountingItemFilters?.status?.length > 0 ? searchAccountingItemFilters.status : [],
        condition: 'IN',
      },
      {
        property: 'accountingTypeValue',
        values:
          searchAccountingItemFilters?.accountingTypeValue?.length > 0
            ? searchAccountingItemFilters.accountingTypeValue
            : [],
        condition: 'IN',
      },
    ].filter((filter) => filter.values && filter.values.length > 0),
  };

  return (
    <>
      {skipQuery ? (
        <AccountingItems
          loading={false}
          accountingItems={[]}
          paging={{ totalNumberOfElements: 0 }}
          pageNumber={pageNumber}
          pageSize={pageSize}
          sortProperty={sorting?.property}
          sortDirection={sorting.direction}
          searchAccountingItemFilters={searchAccountingItemFilters}
          searchTextInput={searchInput}
          onTextSearch={handleTextSearch}
          onApplyFilters={handleApplyFilters}
          onApplySorting={handleSortingChange}
          onPageChange={handleOnPageChange}
        />
      ) : (
        <QueryWrapper
          query={GET_ACCOUNTING_ITEMS}
          options={{
            fetchPolicy: 'no-cache',
            variables: {
              request: request,
            },
          }}
        >
          {({
            accountingItems,
            paging,
            loading,
          }: {
            accountingItems: AccountingItem[];
            paging: Paging;
            loading: boolean;
          }) => (
            <AccountingItems
              loading={loading}
              accountingItems={accountingItems}
              paging={paging}
              pageNumber={pageNumber}
              pageSize={pageSize}
              sortProperty={sorting?.property}
              sortDirection={sorting.direction}
              searchAccountingItemFilters={searchAccountingItemFilters}
              searchTextInput={searchInput}
              onTextSearch={handleTextSearch}
              onApplyFilters={handleApplyFilters}
              onApplySorting={handleSortingChange}
              onPageChange={handleOnPageChange}
            />
          )}
        </QueryWrapper>
      )}
    </>
  );
};
export default AccountingItemsTab;
