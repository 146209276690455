import { Button, Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import './accounting-items-filter-details.scss';
import { SearchAccountingItemFilters } from 'src/types/ledgerStorage/SearchAccountingItemFilters';
import { Employer } from 'src/types/employer/Employer';

interface AccountingItemsFilterDetailsProps {
  filters: SearchAccountingItemFilters;
  applyFilters: (filters: SearchAccountingItemFilters) => void;
  employers: Employer[];
}

export const AccountingItemsFilterDetails: FC<AccountingItemsFilterDetailsProps> = ({
  filters,
  applyFilters,
  employers,
}) => {
  const { t } = useTranslation();

  const filtersApplied =
    (filters?.employerId && filters?.employerId !== null) ||
    filters?.accountingPeriod?.fromPeriod?.length > 0 ||
    filters?.accountingPeriod?.toPeriod?.length > 0 ||
    filters?.insuranceProviderIds?.length > 0 ||
    filters?.accountingTypeValue.length > 0 ||
    filters?.premiumPeriod.fromPeriod.length > 0 ||
    filters?.premiumPeriod.toPeriod.length > 0 ||
    filters?.status.length > 0;

  const adjustToReadablePeriod = (period: string | null): string => {
    if (!period || period.length < 4) return '';
    const year = period.substring(0, 4);
    const month = period.length >= 6 ? `-${period.substring(4, 6)}` : '';
    return `${year}${month}`;
  };

  const removeFromAccountingPeriodFilter = () => {
    applyFilters({
      ...filters,
      accountingPeriod: { fromPeriod: '', toPeriod: filters.accountingPeriod?.toPeriod },
    });
  };

  const removeToAccountingPeriodFilter = () => {
    applyFilters({
      ...filters,
      accountingPeriod: { fromPeriod: filters.accountingPeriod?.fromPeriod, toPeriod: '' },
    });
  };

  const removeFromPremiumPeriodFilter = () => {
    applyFilters({
      ...filters,
      premiumPeriod: { fromPeriod: '', toPeriod: filters.premiumPeriod?.toPeriod },
    });
  };

  const removeToPremiumPeriodFilter = () => {
    applyFilters({
      ...filters,
      premiumPeriod: { fromPeriod: filters.premiumPeriod?.fromPeriod, toPeriod: '' },
    });
  };

  const removeInsuranceProvidersFilter = () => {
    applyFilters({
      ...filters,
      insuranceProviderIds: [],
    });
  };

  const removeAccountingTypeValuesFilter = () => {
    applyFilters({
      ...filters,
      accountingTypeValue: [],
    });
  };

  const removeAccountingStatusesFilter = () => {
    applyFilters({
      ...filters,
      status: [],
    });
  };

  const employerName = filters.employerId
    ? employers.find((employer) => employer.id === filters.employerId)?.legalName || ''
    : '';

  const removeEmployerFilter = () => {
    applyFilters({
      ...filters,
      employerId: null,
    });
  };

  const removeAllFilters = () => {
    applyFilters({
      accountingPeriod: {
        fromPeriod: '',
        toPeriod: '',
      },
      premiumPeriod: {
        fromPeriod: '',
        toPeriod: '',
      },
      employerId: null,
      insuranceProviderIds: [],
      searchInput: '',
      accountingTypeValue: [],
      status: [],
    });
  };

  return (
    <div className="accounting-items-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}

      {filtersApplied && employerName && (
        <>
          {filters?.employerId?.length > 0 && (
            <FilterButton label={`${employerName} `} onClick={removeEmployerFilter} />
          )}
          {filters?.accountingPeriod?.fromPeriod.length > 0 && (
            <FilterButton
              label={`${adjustToReadablePeriod(filters?.accountingPeriod.fromPeriod)} ${t(
                'economy:accounting-items-filters.from-accounting-period-filter',
              )}`}
              onClick={removeFromAccountingPeriodFilter}
            />
          )}
          {filters?.accountingPeriod?.toPeriod.length > 0 && (
            <FilterButton
              label={`${adjustToReadablePeriod(filters?.accountingPeriod.toPeriod)} ${t(
                'economy:accounting-items-filters.to-accounting-period-filter',
              )}`}
              onClick={removeToAccountingPeriodFilter}
            />
          )}
          {filters?.insuranceProviderIds?.length > 0 && (
            <FilterButton
              label={
                filters?.insuranceProviderIds.length +
                ' ' +
                t('economy:accounting-items-filters.insurance-providers-filter')
              }
              onClick={removeInsuranceProvidersFilter}
            />
          )}
          {filters?.premiumPeriod?.fromPeriod.length > 0 && (
            <FilterButton
              label={`${adjustToReadablePeriod(filters?.premiumPeriod.fromPeriod)} ${t(
                'economy:accounting-items-filters.from-premium-period-filter',
              )}`}
              onClick={removeFromPremiumPeriodFilter}
            />
          )}
          {filters?.premiumPeriod?.toPeriod.length > 0 && (
            <FilterButton
              label={`${adjustToReadablePeriod(filters?.premiumPeriod.toPeriod)} ${t(
                'economy:accounting-items-filters.to-premium-period-filter',
              )}`}
              onClick={removeToPremiumPeriodFilter}
            />
          )}
          {filters?.accountingTypeValue?.length > 0 && (
            <FilterButton
              label={
                filters?.accountingTypeValue.length +
                ' ' +
                t('economy:accounting-items-filters.accounting-type-values-filter')
              }
              onClick={removeAccountingTypeValuesFilter}
            />
          )}
          {filters?.status?.length > 0 && (
            <FilterButton
              label={filters?.status.length + ' ' + t('economy:accounting-items-filters.accounting-statuses-filter')}
              onClick={removeAccountingStatusesFilter}
            />
          )}
          {
            <Button
              type="warning"
              size="small"
              label={t('economy:accounting-items-filters.remove-all')}
              onClick={removeAllFilters}
            />
          }
        </>
      )}
    </div>
  );
};

export default AccountingItemsFilterDetails;
