import { Button, Icon, InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculationLock } from 'src/types/employees/CalculationLock';
import { useUpdateCalculationLock } from 'src/service/employeecard/UpdateCalculationLock';
import { PatchCalculationLockInput } from 'src/types/employees/request/PatchCalculationLockInput';
import { Month } from 'src/types/Month';
import { getYearTranslation, useYearOptions } from 'src/util/date/Years';
import {
  convertMonthEnumToStringWithZeroes,
  convertMonthNumberStringToEnum,
  getMonthTranslation,
  useMonthOptions,
} from 'src/util/date/Months';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import When from 'src/components/Common/When';
import { getValidPeriodInput } from '../utils';
import DeleteCalculationLockModal from '../DeleteCalculationLockModal';
import './calculation-lock-details.scss';

interface CalculationLockDetailsProps {
  employerId: string;
  calculationLock: CalculationLock;
  isDraft: boolean;
  onBackClick: () => void;
}

const CalculationLockDetails: FC<CalculationLockDetailsProps> = ({
  employerId,
  calculationLock,
  isDraft,
  onBackClick,
}) => {
  const { t } = useTranslation();
  const [deleteDraftModalOpen, setDeleteDraftModalOpen] = useState(false);

  const fromMonth: Month = convertMonthNumberStringToEnum(calculationLock?.fromPeriod?.substring(4, 6));
  const toMonth: Month = convertMonthNumberStringToEnum(calculationLock?.toPeriod?.substring(4, 6));
  const fromYear: string = calculationLock?.fromPeriod?.substring(0, 4);
  const toYear: string = calculationLock?.toPeriod?.substring(0, 4);

  const [selectedFromMonth, setSelectedFromMonth] = useState<Option<Month>>({
    label: getMonthTranslation(fromMonth),
    value: fromMonth ? fromMonth : null,
  });
  const [selectedFromYear, setSelectedFromYear] = useState<Option<number>>({
    label: getYearTranslation(fromYear),
    value: fromYear ? +fromYear : null,
  });
  const [selectedToMonth, setSelectedToMonth] = useState<Option<Month>>({
    label: getMonthTranslation(toMonth),
    value: toMonth ? toMonth : null,
  });
  const [selectedToYear, setSelectedToYear] = useState<Option<number>>({
    label: getYearTranslation(toYear),
    value: toYear ? +toYear : null,
  });
  const [fromPeriodInput, setFromPeriodInput] = useState<string>(calculationLock?.fromPeriod);
  const [toPeriodInput, setToPeriodInput] = useState<string>(calculationLock?.toPeriod);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const yearsOptions = useYearOptions(true);
  const monthOptions = useMonthOptions(true);

  const updateCalculationLock = useUpdateCalculationLock();

  const handleFromPeriodInputChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedFromYear(yearOption);
    setSelectedFromMonth(monthOption);

    if (monthOption && yearOption) {
      const period = yearOption?.label + convertMonthEnumToStringWithZeroes(monthOption?.value);
      setFromPeriodInput(period);
    }
  };

  const handleToPeriodInputChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedToYear(yearOption);
    setSelectedToMonth(monthOption);

    if (monthOption && yearOption) {
      const period = yearOption?.label + convertMonthEnumToStringWithZeroes(monthOption?.value);
      setToPeriodInput(period);
    }
  };

  const handleSaveChanges = async () => {
    setSaveButtonDisabled(true);
    const request: PatchCalculationLockInput = {
      id: calculationLock.id,
      fromPeriod: fromPeriodInput,
      toPeriod: getValidPeriodInput(toPeriodInput),
    };

    await updateCalculationLock(employerId, request, true);
    onBackClick();
  };

  const handleOpenDeleteCalculationLockModal = () => setDeleteDraftModalOpen(true);
  const handleCloseDeleteCalculationLockModal = () => setDeleteDraftModalOpen(false);

  const currentFromMonth = getMonthTranslation(fromMonth);
  const currentToMonth = getMonthTranslation(toMonth);
  const currentFromYear = getYearTranslation(fromYear);
  const currentToYear = getYearTranslation(toYear);

  useEffect(() => {
    const hasFromMonthChanged = selectedFromMonth?.label !== currentFromMonth;
    const hasFromYearChanged = selectedFromYear?.label !== currentFromYear;
    const hasToMonthChanged = selectedToMonth?.label !== currentToMonth;
    const hasToYearChanged = selectedToYear?.label !== currentToYear;
    const hasAnyValueChanged = hasFromMonthChanged || hasFromYearChanged || hasToMonthChanged || hasToYearChanged;
    const hasValidValues = selectedFromMonth?.value && selectedFromYear?.value;
    const hasValidEmptyToPeriod = !!selectedToMonth?.value === !!selectedToYear?.value;

    if (hasAnyValueChanged && hasValidValues && hasValidEmptyToPeriod) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [selectedFromMonth, selectedToMonth, selectedFromYear, selectedToYear]);

  return (
    <div>
      <When condition={isDraft && !calculationLock?.deleted}>
        <EmployeeInDraftBanner />
        <ListGroup className="calculation-lock-details__period-list-group" variant="inline-edit">
          <InlineEdit label={t('calculations:calculation-locks-drawer.from-period-label')} bold>
            <div className="create-draft-calculation-lock-drawer__period-input">
              <Select
                placeholder={t('common:month')}
                options={monthOptions}
                onChange={(option: Option<Month>) => handleFromPeriodInputChange(option, selectedFromYear)}
                selected={selectedFromMonth}
                alphabetical={false}
                data-testid="dropdown-select-from-month"
                small
              />
              <Select
                placeholder={t('common:year')}
                options={yearsOptions}
                onChange={(option: Option<number>) => handleFromPeriodInputChange(selectedFromMonth, option)}
                selected={selectedFromYear}
                data-testid="dropdown-select-from-year"
                small
              />
            </div>
          </InlineEdit>
          <InlineEdit label={t('calculations:calculation-locks-drawer.to-period-label')} bold>
            <div className="calculation-lock-details__period-input">
              <Select
                placeholder={t('common:month')}
                options={monthOptions}
                onChange={(option: Option<Month>) => handleToPeriodInputChange(option, selectedToYear)}
                selected={selectedToMonth}
                alphabetical={false}
                data-testid="dropdown-select-to-month"
                small
              />
              <Select
                placeholder={t('common:year')}
                options={yearsOptions}
                onChange={(option: Option<number>) => handleToPeriodInputChange(selectedToMonth, option)}
                selected={selectedToYear}
                data-testid="dropdown-select-to-year"
                small
              />
            </div>
          </InlineEdit>
        </ListGroup>

        <div className="calculation-lock-details__buttons">
          <Button
            type="primary"
            label={t('calculations:calculation-locks-drawer.save-calculation-lock')}
            onClick={handleSaveChanges}
            disabled={saveButtonDisabled}
            data-cy="save-calculation-lock-button"
          />
          <Button
            type="secondary"
            icon={<Icon type="Trash" />}
            label={t('calculations:calculation-locks-drawer.delete-calculation-lock')}
            onClick={handleOpenDeleteCalculationLockModal}
            data-cy="delete-calculation-lock-button"
          />
        </div>
        <DeleteCalculationLockModal
          open={deleteDraftModalOpen}
          onClose={handleCloseDeleteCalculationLockModal}
          calculationLockId={calculationLock.id}
          employerId={employerId}
        />
      </When>

      <When condition={!isDraft || calculationLock?.deleted}>
        <When condition={!!calculationLock?.policyPglRuleName?.id}>
          <ListGroup variant="inline-edit">
            <InlineEdit bold label={t('calculations:pgl')}>
              {calculationLock?.policyPglRuleName?.name}
            </InlineEdit>
            <InlineEdit bold label={t('calculations:calculation-locks-drawer.from-period-label')}>
              {calculationLock?.fromPeriod}
            </InlineEdit>
            <InlineEdit bold label={t('calculations:calculation-locks-drawer.to-period-label')}>
              {calculationLock?.toPeriod
                ? calculationLock?.toPeriod
                : t('calculations:calculation-locks-drawer.until-further-notice')}
            </InlineEdit>
          </ListGroup>
        </When>
        <When condition={!!calculationLock?.kubType}>
          <ListGroup variant="inline-edit">
            <InlineEdit bold label={t('calculations:kub')}>
              {calculationLock?.kubType}
            </InlineEdit>
            <InlineEdit bold label={t('calculations:calculation-locks-drawer.from-period-label')}>
              {calculationLock?.fromPeriod}
            </InlineEdit>
            <InlineEdit bold label={t('calculations:calculation-locks-drawer.to-period-label')}>
              {calculationLock?.toPeriod
                ? calculationLock?.toPeriod
                : t('calculations:calculation-locks-drawer.until-further-notice')}
            </InlineEdit>
          </ListGroup>
        </When>
      </When>
    </div>
  );
};

export default CalculationLockDetails;
