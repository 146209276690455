import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { CREATE_CALCULATION_LOCK } from 'src/graphql/schema/mutation/employeecard/CreateCalculationLock';
import { GET_QUALIFIED_CALCULATIONS } from 'src/graphql/schema/query/employee/GetQualifiedCalculations';
import { CreateCalculationLockInput } from 'src/types/employees/request/CreateCalculationLockInput';
import { GET_CALCULATION_LOCKS } from 'src/graphql/schema/query/employee/GetCalculationLocks';

export const useCreateCalculationLock = () => {
  const apolloMutation = useApolloMutation();

  const createCalculationLock = (employerId: string, request: CreateCalculationLockInput) =>
    apolloMutation(
      CREATE_CALCULATION_LOCK,
      employerId,
      {
        request,
      },
      [GET_EMPLOYEE, GET_CALCULATION_LOCKS, GET_QUALIFIED_CALCULATIONS],
    );

  return createCalculationLock;
};
