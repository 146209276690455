import { Loader, SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { AccountingItem } from 'src/types/ledgerStorage/AccountingItem';
import { sortAlphabeticalAsc } from 'src/util/SortUtil';
import AccountingItemsTableHeads from './AccountingItemsTableHeads';
import { AccountingItemsTableSortField } from './AccountingItemsTableSortField';
import AccountingItemTableRow from './AccountingItemTableRow';
import When from 'src/components/Common/When';

interface AccountingItemsTableProps {
  accountingItems: AccountingItem[];
  loading: boolean;
  sortingProperty?: AccountingItemsTableSortField;
  sortDirection?: SortDirection;
  applySorting(property: string, direction: SortDirection);
}

const AccountingItemsTable: FC<AccountingItemsTableProps> = ({
  accountingItems,
  loading,
  sortingProperty,
  sortDirection,
  applySorting,
}) => {
  const handleSort = (property: AccountingItemsTableSortField) => {
    if (property == sortingProperty) {
      applySorting(property, sortDirection == 'UP' ? 'DOWN' : 'UP');
    } else {
      applySorting(property, sortDirection);
    }
  };

  const sortedAccountingItems = accountingItems ? [...accountingItems] : [];

  const uniqueComponentTypes = Array.from(
    new Set(
      sortedAccountingItems.flatMap((item) =>
        item.accountingComponents
          .map((component) => component.accountingComponentValue?.accountingComponentType?.name)
          .filter((type): type is string => !!type),
      ),
    ),
  ).sort(sortAlphabeticalAsc);

  return (
    <>
      <When condition={loading}>
        <Loader className={'accounting-items-tab__table__body__loading'} type="blue" size="large"></Loader>
      </When>
      <TableBase compressed>
        <TableHead filled>
          <AccountingItemsTableHeads
            sortField={sortingProperty}
            sortDirection={sortDirection}
            onApplySorting={handleSort}
            accountingComponentTypes={uniqueComponentTypes}
          />
        </TableHead>
        <TableBody>
          {accountingItems.map((item, index) => (
            <AccountingItemTableRow
              key={`accounting-item-table-row-${item.id}-${index}`}
              accountingItem={item}
              accountingComponentTypes={uniqueComponentTypes}
            />
          ))}
        </TableBody>
      </TableBase>
    </>
  );
};

export default AccountingItemsTable;
