import { Button, Card, CardAction, Icon, Modal, Option, Select, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { createOptions, createOptionsFromList } from 'src/util/OptionUtil';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import './accounting-items-filters-modal.scss';
import { unique } from 'src/util/ArrayUtil';
import { useEmployersInCustomer } from 'src/service/employer/GetEmployersInCustomer';
import { SearchAccountingItemFilters } from 'src/types/ledgerStorage/SearchAccountingItemFilters';
import { getPeriodMonthString, getPeriodYear } from 'src/util/date/PeriodUtil';
import { useYearOptionsWithNextYear } from 'src/util/date/Years';
import {
  convertMonthEnumToStringWithZeroes,
  convertMonthNumberStringToEnum,
  useMonthOptions,
} from 'src/util/date/Months';
import { Month } from 'src/types/Month';
import InfoMessage from 'src/components/Common/InfoMessage';
import { AccountingTypeEnum, accountingTypeValues } from 'src/types/accountingunit/AccountingTypeValue';
import { AccountingItemStatusEnum, accountingItemStatuses } from 'src/types/ledgerStorage/AccountingItem';

interface AccountingItemsFiltersModalProps {
  open: boolean;
  filters: SearchAccountingItemFilters;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchAccountingItemFilters) => void;
  customerId: string;
}

const AccountingItemsFiltersModal: FC<AccountingItemsFiltersModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
  customerId,
}) => {
  const { t } = useTranslation();
  const employers = useEmployersInCustomer(customerId, 'cache-first');
  const [newFilters, setNewFilters] = useState<SearchAccountingItemFilters>({
    ...filters,
  });
  const [selectedEmployerId, setSelectedEmployerId] = useState<string>(null);

  const [fromAccountingPeriodYear, setFromAccountingPeriodYear] = useState<number>(
    getPeriodYear(filters.accountingPeriod?.fromPeriod),
  );
  const [fromAccountingPeriodMonth, setFromAccountingPeriodMonth] = useState<string>(
    getPeriodMonthString(filters.accountingPeriod?.fromPeriod),
  );
  const [toAccountingPeriodYear, setToAccountingPeriodYear] = useState<number>(
    getPeriodYear(filters.accountingPeriod?.toPeriod),
  );
  const [toAccountingPeriodMonth, setToAccountingPeriodMonth] = useState<string>(
    getPeriodMonthString(filters.accountingPeriod?.toPeriod),
  );

  const [fromPremiumPeriodYear, setFromPremiumPeriodYear] = useState<number>(
    getPeriodYear(filters.premiumPeriod?.fromPeriod),
  );
  const [fromPremiumPeriodMonth, setFromPremiumPeriodMonth] = useState<string>(
    getPeriodMonthString(filters.premiumPeriod?.fromPeriod),
  );
  const [toPremiumPeriodYear, setToPremiumPeriodYear] = useState<number>(
    getPeriodYear(filters.premiumPeriod?.toPeriod),
  );
  const [toPremiumPeriodMonth, setToPremiumPeriodMonth] = useState<string>(
    getPeriodMonthString(filters.premiumPeriod?.toPeriod),
  );

  const yearsOptions = useYearOptionsWithNextYear(true);
  const monthOptions = useMonthOptions(true);

  const handleEmployerSelectChange = (option: Option<string>) => {
    setSelectedEmployerId(option.value);
    setNewFilters((prev) => ({
      ...prev,
      employerId: option.value,
    }));
  };

  const handleInsuranceProvidersFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      insuranceProviderIds: options.map((option) => option.value),
    });
  };

  const handleFromAccountingPeriodYearChange = (value: number) => {
    setFromAccountingPeriodYear(value);
    if (!fromAccountingPeriodMonth) return;

    setNewFilters({
      ...newFilters,
      accountingPeriod: {
        fromPeriod: `${value}${fromAccountingPeriodMonth}`,
        toPeriod: newFilters.accountingPeriod.toPeriod,
      },
    });
  };

  useEffect(() => {
    if (open) {
      setNewFilters(filters);
      setSelectedEmployerId(filters?.employerId || null);
    }
  }, [open, filters]);

  const handleFromAccountingPeriodMonthChange = (value: string) => {
    setFromAccountingPeriodMonth(value);
    if (!fromAccountingPeriodYear) return;

    setNewFilters({
      ...newFilters,
      accountingPeriod: {
        fromPeriod: `${fromAccountingPeriodYear}${value}`,
        toPeriod: newFilters.accountingPeriod.toPeriod,
      },
    });
  };

  const handleToAccountingPeriodYearChange = (value: number) => {
    setToAccountingPeriodYear(value);
    if (!toAccountingPeriodMonth) return;

    setNewFilters({
      ...newFilters,
      accountingPeriod: {
        fromPeriod: newFilters.accountingPeriod.fromPeriod,
        toPeriod: `${value}${toAccountingPeriodMonth}`,
      },
    });
  };

  const handleToAccountingPeriodMonthChange = (value: string) => {
    setToAccountingPeriodMonth(value);
    if (!toAccountingPeriodYear) return;

    setNewFilters({
      ...newFilters,
      accountingPeriod: {
        fromPeriod: newFilters.accountingPeriod.fromPeriod,
        toPeriod: `${toAccountingPeriodYear}${value}`,
      },
    });
  };

  const handleFromPremiumPeriodYearChange = (value: number) => {
    setFromPremiumPeriodYear(value);
    if (!fromPremiumPeriodMonth) return;

    setNewFilters({
      ...newFilters,
      premiumPeriod: {
        fromPeriod: `${value}${fromPremiumPeriodMonth}`,
        toPeriod: newFilters.premiumPeriod.toPeriod,
      },
    });
  };

  const handleFromPremiumPeriodMonthChange = (value: string) => {
    setFromPremiumPeriodMonth(value);
    if (!fromPremiumPeriodYear) return;

    setNewFilters({
      ...newFilters,
      premiumPeriod: {
        fromPeriod: `${fromPremiumPeriodYear}${value}`,
        toPeriod: newFilters.premiumPeriod.toPeriod,
      },
    });
  };

  const handleToPremiumPeriodYearChange = (value: number) => {
    setToPremiumPeriodYear(value);
    if (!toPremiumPeriodMonth) return;

    setNewFilters({
      ...newFilters,
      premiumPeriod: {
        fromPeriod: newFilters.premiumPeriod.fromPeriod,
        toPeriod: `${value}${toPremiumPeriodMonth}`,
      },
    });
  };

  const handleToPremiumPeriodMonthChange = (value: string) => {
    setToPremiumPeriodMonth(value);
    if (!toPremiumPeriodYear) return;

    setNewFilters({
      ...newFilters,
      premiumPeriod: {
        fromPeriod: newFilters.premiumPeriod.fromPeriod,
        toPeriod: `${toPremiumPeriodYear}${value}`,
      },
    });
  };

  const handleAccountingStatusesFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      status: options.map((option) => option.value),
    });
  };

  const handleAccountingTypeValuesFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      accountingTypeValue: options.map((option) => option.value),
    });
  };

  useEffect(() => {
    setNewFilters(filters);
    setFromAccountingPeriodYear(getPeriodYear(filters.accountingPeriod?.fromPeriod));
    setFromAccountingPeriodMonth(getPeriodMonthString(filters.accountingPeriod?.fromPeriod));
    setToAccountingPeriodYear(getPeriodYear(filters.accountingPeriod?.toPeriod));
    setToAccountingPeriodMonth(getPeriodMonthString(filters.accountingPeriod?.toPeriod));
  }, [filters, open]);

  const onApply = () => {
    const updatedFilters: SearchAccountingItemFilters = {
      ...newFilters,
      accountingPeriod: {
        fromPeriod: fromAccountingPeriodYear ? `${fromAccountingPeriodYear}${fromAccountingPeriodMonth || ''}` : '',
        toPeriod: toAccountingPeriodYear ? `${toAccountingPeriodYear}${toAccountingPeriodMonth || ''}` : '',
      },
      premiumPeriod: {
        fromPeriod: fromPremiumPeriodYear ? `${fromPremiumPeriodYear}${fromPremiumPeriodMonth || ''}` : '',
        toPeriod: toPremiumPeriodYear ? `${toPremiumPeriodYear}${toPremiumPeriodMonth || ''}` : '',
      },
      employerId: newFilters?.employerId ? newFilters.employerId : null,
      insuranceProviderIds: newFilters.insuranceProviderIds.length > 0 ? [...newFilters.insuranceProviderIds] : [],
      accountingTypeValue: newFilters.accountingTypeValue.length > 0 ? [...newFilters.accountingTypeValue] : [],
      status: newFilters.status.length > 0 ? [...newFilters.status] : [],
    };
    onApplyFilters(updatedFilters);
    onClose();
  };

  const employerOptions =
    employers.status !== 'loaded'
      ? []
      : employers.payload.map(
          (employer) =>
            ({
              label: employer.legalName,
              value: employer.id,
            } as Option<string>),
        );

  const useButtonDisabled: boolean =
    !selectedEmployerId ||
    (fromAccountingPeriodYear !== null && fromAccountingPeriodMonth === null) ||
    (toAccountingPeriodYear !== null && toAccountingPeriodMonth === null) ||
    (fromPremiumPeriodYear !== null && fromPremiumPeriodMonth === null) ||
    (toPremiumPeriodYear !== null && toPremiumPeriodMonth === null);

  const accountingTypeValueOptions: Option<AccountingTypeEnum>[] = createOptions(
    accountingTypeValues,
    t,
    'accountingTypeValue',
  );
  const accountingTypeStatusOptions: Option<AccountingItemStatusEnum>[] = createOptions(
    accountingItemStatuses,
    t,
    'accountingItems:accounting-statuses',
  );

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="accounting-items-filters-modal"
        title={t('economy:accounting-items-filters-modal.title')}
        icon={<Icon type="Sliders" className="accounting-items-filters-modal__icon" />}
        size="wide"
      >
        <div className="accounting-items-filters-modal__filters"></div>
        <div className="accounting-items-filters-modal__select">
          <Select
            data-testid="employer-select"
            placeholder={t('common:select')}
            label={t('economy:accounting-items-filters-modal.employer-label')}
            options={employerOptions}
            selected={employerOptions.find((option) => selectedEmployerId === option.value)}
            onChange={handleEmployerSelectChange}
            small
            outlined
          />
        </div>
        <QueryWrapper
          query={GET_INSURANCE_PROVIDERS}
          type="list"
          options={{ variables: { employerId: newFilters.employerId }, skip: !newFilters.employerId }}
        >
          {(insuranceProviders: InsuranceProvider[]) => {
            const insuranceProviderOptions = unique(createOptionsFromList(insuranceProviders, 'id', 'legalName'));
            return (
              <div className="accounting-items-filters-modal__filters">
                <div className="accounting-items-filters-modal__select">
                  <Select
                    label={t('economy:accounting-items-filters-modal.insurance-provider-label')}
                    placeholder={t('common:select')}
                    selectAll
                    options={insuranceProviderOptions}
                    selected={insuranceProviderOptions?.filter((option) =>
                      newFilters?.insuranceProviderIds?.includes(option.value),
                    )}
                    disabled={!selectedEmployerId}
                    onChange={handleInsuranceProvidersFilterChange}
                    small
                    outlined
                    multiple
                  />
                </div>
              </div>
            );
          }}
        </QueryWrapper>
        <div className="accounting-items-filters-modal__filters">
          <div className="accounting-items-filters-modal__select">
            <Select
              label={t('economy:accounting-items-filters-modal.accounting-status-label')}
              placeholder={t('common:select')}
              selectAll
              options={accountingTypeStatusOptions}
              selected={accountingTypeStatusOptions?.filter((option) => newFilters?.status?.includes(option.value))}
              disabled={!selectedEmployerId}
              onChange={handleAccountingStatusesFilterChange}
              small
              outlined
              multiple
            />
          </div>

          <div className="accounting-items-filters-modal__select">
            <Select
              label={t('economy:accounting-items-filters-modal.accounting-type-value-label')}
              placeholder={t('common:select')}
              selectAll
              options={accountingTypeValueOptions}
              selected={accountingTypeValueOptions?.filter((option) =>
                newFilters?.accountingTypeValue?.includes(option.value),
              )}
              disabled={!selectedEmployerId}
              onChange={handleAccountingTypeValuesFilterChange}
              small
              outlined
              multiple
            />
          </div>
        </div>

        <div className="accounting-items-filters-modal__date-section">
          <div className="date-filter">
            <div className="date-filter__period">
              <Typography component="label" variant="caption" className="date-filter__label">
                {t('economy:accounting-items-filters-modal.accounting-period-start-label')}
              </Typography>
              <div className="date-filter__period__selects">
                <Select
                  options={yearsOptions}
                  alphabetical={false}
                  onChange={(e: Option<number>) => handleFromAccountingPeriodYearChange(e.value)}
                  selected={yearsOptions.find((option) => option.value === fromAccountingPeriodYear)}
                  data-testid="start-year-select"
                  small
                  outlined
                  disabled={!selectedEmployerId}
                />
                <Select
                  options={monthOptions}
                  alphabetical={false}
                  onChange={(e: Option<Month>) =>
                    handleFromAccountingPeriodMonthChange(convertMonthEnumToStringWithZeroes(e.value))
                  }
                  selected={monthOptions.find(
                    (option) =>
                      option.value ===
                      (fromAccountingPeriodMonth === null
                        ? null
                        : convertMonthNumberStringToEnum(fromAccountingPeriodMonth)),
                  )}
                  disabled={!fromAccountingPeriodYear}
                  data-testid="start-month-select"
                  small
                  outlined
                />
              </div>
            </div>
            <div className="date-filter__period">
              <Typography component="label" variant="caption" className="date-filter__label">
                {t('economy:accounting-items-filters-modal.accounting-period-end-label')}
              </Typography>
              <div className="date-filter__period__selects">
                <Select
                  options={yearsOptions}
                  alphabetical={false}
                  onChange={(e: Option<number>) => handleToAccountingPeriodYearChange(e.value)}
                  selected={yearsOptions.find((option) => option.value === toAccountingPeriodYear)}
                  data-testid="end-year-select"
                  small
                  outlined
                  disabled={!selectedEmployerId}
                />
                <Select
                  options={monthOptions}
                  alphabetical={false}
                  onChange={(e: Option<Month>) =>
                    handleToAccountingPeriodMonthChange(convertMonthEnumToStringWithZeroes(e.value))
                  }
                  selected={monthOptions.find(
                    (option) =>
                      option.value ===
                      (toAccountingPeriodMonth === null
                        ? null
                        : convertMonthNumberStringToEnum(toAccountingPeriodMonth)),
                  )}
                  disabled={toAccountingPeriodYear === null}
                  data-testid="end-month-select"
                  small
                  outlined
                />
              </div>
            </div>
            <div className="date-filter__period">
              <Typography component="label" variant="caption" className="date-filter__label">
                {t('economy:accounting-items-filters-modal.premium-period-start-label')}
              </Typography>
              <div className="date-filter__period__selects">
                <Select
                  options={yearsOptions}
                  alphabetical={false}
                  onChange={(e: Option<number>) => handleFromPremiumPeriodYearChange(e.value)}
                  selected={yearsOptions.find((option) => option.value === fromPremiumPeriodYear)}
                  data-testid="start-year-select"
                  small
                  outlined
                  disabled={!selectedEmployerId}
                />
                <Select
                  options={monthOptions}
                  alphabetical={false}
                  onChange={(e: Option<Month>) =>
                    handleFromPremiumPeriodMonthChange(convertMonthEnumToStringWithZeroes(e.value))
                  }
                  selected={monthOptions.find(
                    (option) =>
                      option.value ===
                      (fromPremiumPeriodMonth === null ? null : convertMonthNumberStringToEnum(fromPremiumPeriodMonth)),
                  )}
                  disabled={!fromPremiumPeriodYear}
                  data-testid="start-month-select"
                  small
                  outlined
                />
              </div>
            </div>
            <div className="date-filter__period">
              <Typography component="label" variant="caption" className="date-filter__label">
                {t('economy:accounting-items-filters-modal.premium-period-end-label')}
              </Typography>
              <div className="date-filter__period__selects">
                <Select
                  options={yearsOptions}
                  alphabetical={false}
                  onChange={(e: Option<number>) => handleToPremiumPeriodYearChange(e.value)}
                  selected={yearsOptions.find((option) => option.value === toPremiumPeriodYear)}
                  data-testid="end-year-select"
                  small
                  outlined
                  disabled={!selectedEmployerId}
                />
                <Select
                  options={monthOptions}
                  alphabetical={false}
                  onChange={(e: Option<Month>) =>
                    handleToPremiumPeriodMonthChange(convertMonthEnumToStringWithZeroes(e.value))
                  }
                  selected={monthOptions.find(
                    (option) =>
                      option.value ===
                      (toPremiumPeriodMonth === null ? null : convertMonthNumberStringToEnum(toPremiumPeriodMonth)),
                  )}
                  disabled={toPremiumPeriodYear === null}
                  data-testid="end-month-select"
                  small
                  outlined
                />
              </div>
            </div>
          </div>
          <InfoMessage message={t('common:period-filter-info-message')} />
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button
            label={t('economy:accounting-items-filters-modal.use-button')}
            onClick={onApply}
            disabled={useButtonDisabled}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AccountingItemsFiltersModal;
