import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, InlineEdit, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import DrawerBase from 'src/components/Common/DrawerBase';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { KubOutcome } from 'src/types/employees/KubOutcome';
import { GET_KUB_OUTCOME } from 'src/graphql/schema/query/employee/GetKubOutcome';
import {
  formatSweAmountTextWithSuffixNoDecimal,
  formatSweAmountTextWithSuffixWithTwoDecimals,
} from 'src/util/Number/AmountFormatter';
import './kub-details-drawer.scss';
import { stringOrBlank } from 'src/util/StringUtil';
import DetailsDrawerTitle from 'src/components/Employees/EmployeeOverview/calculations/DetailsDrawerTitle';
import When from 'src/components/Common/When';

interface KubDetailsDrawerProps {
  onClose: () => void;
  onBackClick: () => void;
  kubId: string;
  periodText: string;
}

export const KubDetailsDrawer: FC<KubDetailsDrawerProps> = ({ onClose, onBackClick, kubId, periodText }) => {
  const { t } = useTranslation();

  return (
    <QueryWrapper query={GET_KUB_OUTCOME} options={{ variables: { kubId } }}>
      {(kub: KubOutcome) => (
        <DrawerBase
          title={<DetailsDrawerTitle type={'KUB'} name={kub.kubType} period={periodText} locked={!!kub.lockedKubSum} />}
          leftButton={
            <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
          }
          onClose={onClose}
        >
          <div className="kub-details-drawer">
            <ListGroup variant="inline-edit">
              {kub.includedWages.map((wage) => {
                const isManuallyEdited = wage.source === 'MANUAL';

                return (
                  <InlineEdit
                    label={
                      <div className="kub-details-drawer__label">
                        <Typography variant="body2" bold className="kub-details-drawer__label--text">
                          {`${stringOrBlank(wage.payTypeNumber)} - ${stringOrBlank(wage.payTypeName)}`}
                        </Typography>
                        {isManuallyEdited && (
                          <Pill type="heads-up" label={t('calculations:manually-edited')} size="small" />
                        )}
                      </div>
                    }
                    bold
                  >
                    <Typography variant="body2">{`${formatSweAmountTextWithSuffixWithTwoDecimals(
                      wage.payedAmount,
                    )}`}</Typography>
                  </InlineEdit>
                );
              })}
            </ListGroup>
            <ListGroup variant="inline-edit">
              <InlineEdit label={t('calculations:sum')} bold>
                <When condition={kub?.lockedKubSum !== null}>
                  <div className="kub-details-drawer--locked-sum">
                    <Icon type="Lock" />
                    <Typography variant="body2">{formatSweAmountTextWithSuffixNoDecimal(kub?.lockedKubSum)}</Typography>
                  </div>
                  <div className="kub-details-drawer--text">
                    <Typography variant="body2">{`(${formatSweAmountTextWithSuffixNoDecimal(kub.kubSum)})`}</Typography>
                  </div>
                </When>
                <When condition={!kub?.lockedKubSum}>
                  <Typography variant="body2">{formatSweAmountTextWithSuffixNoDecimal(kub.kubSum)}</Typography>
                </When>
              </InlineEdit>
            </ListGroup>
          </div>
        </DrawerBase>
      )}
    </QueryWrapper>
  );
};
export default KubDetailsDrawer;
