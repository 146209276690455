import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import './interactive-card-box.scss';

interface InteractiveCardBoxProps {
  label?: string | JSX.Element | JSX.Element[];
  value?: string | JSX.Element | JSX.Element[];
  index?: number;
  children?: JSX.Element | JSX.Element[];
  onClick?: () => void;
  className?: string;
}

const InteractiveCardBox: FC<InteractiveCardBoxProps> = ({
  label,
  value,
  index,
  children,
  onClick,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={[className, 'interactive-card-box'].join(' ')}
      key={`interactive-card-box-${label}--${index}`}
      onClick={onClick}
    >
      {typeof label === 'string' ? (
        <Typography className="interactive-card-box--label" variant="body2" bold>
          {label}
        </Typography>
      ) : (
        <>{label}</>
      )}
      {children}
      <Typography bold variant="body2">
        {value}
      </Typography>
    </div>
  );
};

export default InteractiveCardBox;
