import { Button, Icon, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { CalculationLock } from 'src/types/employees/CalculationLock';
import CalculationLockDetails from '../CalculationLockDetails/CalculationLockDetails';
import './calculation-lock-details-drawer.scss';

interface CalculationLockDetailsDrawerProps {
  periodText: string;
  employerId: string;
  onClose: () => void;
  onBackClick: () => void;
  calculationLock: CalculationLock;
  isDraft: boolean;
}

const CalculationLockDetailsDrawer: FC<CalculationLockDetailsDrawerProps> = ({
  periodText,
  employerId,
  onClose,
  onBackClick,
  calculationLock,
  isDraft,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerBase
      title={
        <div className="calculation-lock-details-drawer__heading">
          <Typography className="calculation-lock-details-drawer__heading--text" variant="h3" bold component="h3">
            {calculationLock?.policyPglRuleName?.name
              ? calculationLock?.policyPglRuleName?.name
              : calculationLock?.kubType}
          </Typography>
          {calculationLock?.deleted && (
            <Pill label={t('calculations:calculation-locks-drawer.deleted-pill')} type="warning" size="large" />
          )}
        </div>
      }
      subTitle={<Pill type="success" label={periodText} />}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <div className="calculation-lock-details-drawer">
        <CalculationLockDetails
          employerId={employerId}
          isDraft={isDraft}
          calculationLock={calculationLock}
          onBackClick={onBackClick}
        />
      </div>
    </DrawerBase>
  );
};

export default CalculationLockDetailsDrawer;
