import { useTranslation } from 'react-i18next';
import { Typography } from 'fgirot-k2-ui-components';
import EmployeeCardSection from '../EmployeeCardSection';
import BenefitsOverviewSection from '../benefits/BenefitsOverviewSection';
import WagesOverviewSection from '../wages/WagesOverviewSection';
import CalculationsOverviewSection from '../calculations/CalculationsOverviewSection';
import React from 'react';
import { useEmployeeCardHistoricalAggregatedWages } from 'src/service/employeecard/FindEmployeeCardHistoricalAggregatedWages';
import { filterAbsenceWageTypes } from './utils';
import AbsenceOverviewSection from '../absence/AbsenceOverviewSection';
import IndividualChoicesSection from '../EmployeeIndividualChoices';
import { Employee } from 'src/types/Employee';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { EmployeeComment } from 'src/types/employees/EmployeeComment';
import CommentsOverviewSection from '../comments/EmployeeCommentsOverviewSection/EmployeeCommentsOverviewSection';
import { GET_EMPLOYEE_COMMENTS } from 'src/graphql/schema/query/employee/GetEmployeeComments';
import { GET_CALCULATION_LOCKS } from 'src/graphql/schema/query/employee/GetCalculationLocks';
import { CalculationLock } from 'src/types/employees/CalculationLock';

interface LatestEmployeeCardProps {
  employerName: string;
  employee: Employee;
  employeeCard: AggregatedEmployeeCard;
  period: string;
  employeeId: string;
}

const LatestEmployeeCard = ({ employerName, employee, employeeCard, period, employeeId }: LatestEmployeeCardProps) => {
  const { t } = useTranslation();
  const year = period.slice(0, 4);
  const aggregatedEmployeeCardWages = useEmployeeCardHistoricalAggregatedWages(
    employeeId,
    year + '01',
    year + '12',
    true,
  );

  const aggregatedAbsenceEmployeeCards = filterAbsenceWageTypes(aggregatedEmployeeCardWages);

  return (
    <div className="employee-overview__cards">
      <div className="employee-overview__card-row">
        <div className="employee-overview__overview">
          <Typography variant="subtitle" bold>
            {employerName}
          </Typography>
          <div data-cy="employement-overview-property" className="employee-overview__employment-properties">
            <Typography variant="caption2" className="employee-overview__overview-label">
              {t('employees:employmentNumber')}
            </Typography>
            <Typography variant="body2" bold className="employee-overview__overview-value">
              {employee.employmentNumber}
            </Typography>
            <Typography variant="caption2" className="employee-overview__overview-label">
              {t('employees:added-from')}
            </Typography>
            <Typography variant="body2" bold className="employee-overview__overview-value">
              {t(`employees:employee-source-types.${employee.employeeSourceType}`)}
            </Typography>
          </div>
        </div>
        <EmployeeCardSection employee={employee} employeeCard={employeeCard} period={period} />
        <IndividualChoicesSection employee={employee} />
      </div>
      <div className="employee-overview__card-row">
        <BenefitsOverviewSection period={period} employeeId={employeeId} employeeCard={employeeCard} />
        <WagesOverviewSection
          employee={employee}
          period={period}
          aggregatedEmployeeCardWages={aggregatedEmployeeCardWages}
        />
        <QueryWrapper
          query={GET_CALCULATION_LOCKS}
          options={{ variables: { employeeId: employee?.id, allowDrafts: true } }}
        >
          {(calculationLocks: CalculationLock[]) => (
            <CalculationsOverviewSection
              employeeCard={employeeCard}
              employerId={employee.employerId}
              employee={employee}
              calculationLocks={calculationLocks}
            />
          )}
        </QueryWrapper>
        <AbsenceOverviewSection
          employee={employee}
          period={period}
          aggregatedAbsenceEmployeeCards={aggregatedAbsenceEmployeeCards}
          employeeCardId={employeeCard?.id}
        />
        <QueryWrapper query={GET_EMPLOYEE_COMMENTS} options={{ variables: { employeeId } }}>
          {(employeeComments: EmployeeComment[]) => (
            <CommentsOverviewSection employeeComments={employeeComments} employee={employee} />
          )}
        </QueryWrapper>
      </div>
    </div>
  );
};

export default LatestEmployeeCard;
