import { Banner, DrawerNavigation, Icon, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import { EmployeeCardCalculation } from 'src/types/employees/EmployeeCardCalculation';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import CalculationLabel from '../CalculationLabel';
import './calculations-about-drawer-tab.scss';

interface CalculationAboutDrawerTabProps {
  qualifiedCalculations: EmployeeCardCalculation;
  onDisplayPglDetailsDrawer: (id: string) => void;
  onDisplayKubDetailsDrawer: (id: string) => void;
}

const CalculationAboutDrawerTab: FC<CalculationAboutDrawerTabProps> = ({
  qualifiedCalculations,
  onDisplayPglDetailsDrawer,
  onDisplayKubDetailsDrawer,
}) => {
  const { t } = useTranslation();

  return (
    <div className="calculations-about-drawer-tab">
      <Typography className="calculations-about-drawer-tab__heading" variant="body1" component="h3" bold>
        {t('calculations:pgl')}
      </Typography>
      <ListGroup variant="drawer-navigation">
        {qualifiedCalculations?.pglOutcomes.length === 0 ? (
          <Banner type="announcement" message={t('calculations:no-pgl-outcomes')} />
        ) : (
          qualifiedCalculations.pglOutcomes.map((pglOutcome) => (
            <DrawerNavigation
              key={pglOutcome.id}
              label={<CalculationLabel label={pglOutcome.name} type={pglOutcome.pglType} />}
              value={
                <>
                  <When condition={!pglOutcome.lockedPglSum}>
                    <>{formatSweAmountTextWithSuffixNoDecimal(pglOutcome.pglSum)}</>
                  </When>
                  <When condition={pglOutcome.lockedPglSum !== null}>
                    <div className="calculations-about-drawer-tab__locked-sum">
                      <Icon type="Lock" />
                      {formatSweAmountTextWithSuffixNoDecimal(pglOutcome.lockedPglSum)}
                    </div>
                  </When>
                </>
              }
              onClick={() => onDisplayPglDetailsDrawer(pglOutcome.id)}
              data-cy={`drawer-navigation__pgl-${pglOutcome.name}`}
            />
          ))
        )}
      </ListGroup>
      <Typography className="calculations-about-drawer-tab__heading" variant="body1" component="h3" bold>
        {t('calculations:kub')}
      </Typography>
      <ListGroup variant="drawer-navigation">
        {qualifiedCalculations.kubOutcomes.length === 0 ? (
          <Banner type="announcement" message={t('calculations:no-kub-outcomes')} />
        ) : (
          qualifiedCalculations.kubOutcomes.map((kubOutcome) => (
            <DrawerNavigation
              key={kubOutcome.id}
              label={kubOutcome.kubType}
              value={
                <>
                  <When condition={!kubOutcome.lockedKubSum}>
                    <>{formatSweAmountTextWithSuffixNoDecimal(kubOutcome.kubSum)}</>
                  </When>
                  <When condition={kubOutcome.lockedKubSum !== null}>
                    <div className="calculations-about-drawer-tab__locked-sum">
                      <Icon type="Lock" />
                      {formatSweAmountTextWithSuffixNoDecimal(kubOutcome.lockedKubSum)}
                    </div>
                  </When>
                </>
              }
              onClick={() => onDisplayKubDetailsDrawer(kubOutcome.id)}
              data-cy={`drawer-navigation__kub-${kubOutcome.kubType}`}
            />
          ))
        )}
      </ListGroup>
    </div>
  );
};

export default CalculationAboutDrawerTab;
