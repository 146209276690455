import React, { FC, useMemo, useState } from 'react';
import { Paging } from 'src/types/PageableResponse';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Button, Icon, Loader, Pagination, SortDirection } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { AccountingItem } from 'src/types/ledgerStorage/AccountingItem';
import AccountingItemsTable from './AccountingItemsTable';
import AccountingItemsFiltersModal from './AccountingItemsFiltersModal';
import { exportAccountingItems } from 'src/service/accountingitem/ExportAccountingItems';
import AccountingItemsFilterDetails from './AccountingItemsFilterDetails';
import When from 'src/components/Common/When';
import { useCustomer } from 'src/service/customer/GetCustomer';
import { AccountingItemsTableSortField } from './AccountingItemsTable/AccountingItemsTableSortField';
import { SearchAccountingItemFilters } from 'src/types/ledgerStorage/SearchAccountingItemFilters';
import SearchInput from '../../SearchInput';
import { useEmployersInCustomer } from 'src/service/employer/GetEmployersInCustomer';

interface AccountingItemsProps {
  accountingItems: AccountingItem[];
  paging: Paging;
  loading: boolean;
  pageNumber: number;
  pageSize: number;
  sortProperty?: AccountingItemsTableSortField;
  sortDirection: SortDirection;
  searchAccountingItemFilters: SearchAccountingItemFilters;
  searchTextInput: string;
  onTextSearch: (searchInput: string) => void;
  onApplyFilters: (filters: SearchAccountingItemFilters) => void;
  onApplySorting: (property: string, sortDirection: SortDirection) => void;
  onPageChange: (pageSize: number) => void;
}

const AccountingItems: FC<AccountingItemsProps> = ({
  accountingItems,
  paging,
  loading,
  pageNumber,
  pageSize,
  sortProperty,
  sortDirection,
  searchAccountingItemFilters,
  searchTextInput,
  onTextSearch,
  onApplyFilters,
  onApplySorting,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const totalPages = useMemo(
    () => getTotalPages(paging.totalNumberOfElements, pageSize),
    [paging.totalNumberOfElements, pageSize],
  );
  const customer = useCustomer();
  const employers = useEmployersInCustomer(customer?.id, 'cache-first');

  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const handleExportButton = () => {
    setFileDownloadLoading(true);
    exportAccountingItems(searchAccountingItemFilters).finally(() => setFileDownloadLoading(false));
  };

  return (
    <>
      <div className="accounting-items-tab">
        <SearchInput
          onSearch={onTextSearch}
          searchInput={searchTextInput}
          placeHolder={t('economy:accounting-items-tab.search-placeholder')}
          type={'text'}
        />
        <AccountingItemsFilterDetails
          filters={searchAccountingItemFilters}
          applyFilters={onApplyFilters}
          employers={employers.status === 'loaded' ? employers.payload : []}
        />
        <div className="accounting-items-tab__export-container">
          <Button
            disabled={accountingItems == undefined || accountingItems?.length <= 0}
            className="accounting-items-tab__export-container__button"
            label={
              t('economy:accounting-items-tab.download-button') +
              (paging?.totalNumberOfElements ? ` (${paging?.totalNumberOfElements})` : ' (0)')
            }
            onClick={handleExportButton}
          />
          <When condition={fileDownloadLoading}>
            <Loader type="blue" size="medium"></Loader>
          </When>
        </div>
        <AccountingItemsTable
          loading={loading}
          accountingItems={accountingItems}
          applySorting={onApplySorting}
          sortingProperty={sortProperty}
          sortDirection={sortDirection}
        />
      </div>
      <When condition={filtersModalOpen}>
        <AccountingItemsFiltersModal
          open={filtersModalOpen}
          onClose={handleCloseModal}
          onApplyFilters={onApplyFilters}
          filters={searchAccountingItemFilters}
          customerId={customer?.id}
        />
      </When>
      {totalPages > 1 && <Pagination currentPage={pageNumber} onPageChange={onPageChange} totalPages={totalPages} />}
      <div className="accounting-items-tab__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalOpen}
          data-testid="filter-button"
          data-cy="filter-button"
        />
      </div>
    </>
  );
};
export default AccountingItems;
