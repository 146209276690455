import { gql } from '@apollo/client';

export const GET_ACCOUNTING_ITEMS = gql`
  query GetAccountingItems($request: CommonSearchInput) {
    accountingItems(request: $request) {
      paging {
        totalNumberOfElements
        hasNext
      }
      accountingItems {
        id
        employee {
          id
          firstName
          lastName
          personNumber
        }
        status
        accountingNumber
        accountingTypeValue
        accountingComponents {
          id
          name
          accountingComponentValue {
            id
            name
            accountingComponentType {
              id
              name
            }
          }
        }
        amount
        invoiceNumber
        accountingPeriod
        premiumPeriod
        insuranceTypeMapping {
          id
          name
        }
        insuranceProvider {
          id
          legalName
        }
        insurance {
          id
          benefitType
        }
      }
    }
  }
`;
