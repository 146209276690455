export interface AccountingTypeValue {
  name: AccountingTypeEnum;
  number: string;
  allowedMultipleAccountingRows: boolean;
  defaultAccountingComponentSource: string[];
  validAccountingComponentSources: string[];
}

export type AccountingTypeEnum =
  | 'RESULT_ACCOUNT'
  | 'ACCRUAL_ACCOUNT'
  | 'ROUNDING_ACCOUNT'
  | 'DEBT_ACCOUNT'
  | 'VAT_ACCOUNT'
  | 'CALCULATED_SALARY_TAX_ACCOUNT'
  | 'CALCULATED_ACCRUED_SALARY_TAX_ACCOUNT'
  | 'ACCRUAL_SALARY_TAX_ACCOUNT'
  | 'PRI_SALARY_TAX_DEPOSITION_ACCOUNT'
  | 'PRI_RESULT_DEPOSITION_ACCOUNT'
  | 'PRI_CALCULATED_ACCRUED_SALARY_TAX_DEPOSITION_ACCOUNT'
  | 'PRI_CALCULATED_SALARY_TAX_DEPOSITION_ACCOUNT';

export const accountingTypeValues: AccountingTypeEnum[] = [
  'ACCRUAL_ACCOUNT',
  'ACCRUAL_SALARY_TAX_ACCOUNT',
  'CALCULATED_ACCRUED_SALARY_TAX_ACCOUNT',
  'CALCULATED_SALARY_TAX_ACCOUNT',
  'DEBT_ACCOUNT',
  'PRI_CALCULATED_ACCRUED_SALARY_TAX_DEPOSITION_ACCOUNT',
  'PRI_CALCULATED_SALARY_TAX_DEPOSITION_ACCOUNT',
  'PRI_RESULT_DEPOSITION_ACCOUNT',
  'PRI_SALARY_TAX_DEPOSITION_ACCOUNT',
  'RESULT_ACCOUNT',
  'ROUNDING_ACCOUNT',
  'VAT_ACCOUNT',
];
