import { Button, Card, CardAction, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteCalculationLock } from 'src/service/employeecard/DeleteCalculationLock';

interface DeleteCalculationLockModalProps {
  open: boolean;
  onClose: () => void;
  calculationLockId: string;
  employerId: string;
}

const DeleteCalculationLockModal: FC<DeleteCalculationLockModalProps> = ({
  open,
  onClose,
  calculationLockId,
  employerId,
}) => {
  const { t } = useTranslation();
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const deleteCalculationLock = useDeleteCalculationLock();

  const handleDeleteCalculationLock = async () => {
    setDeleteButtonDisabled(true);
    await deleteCalculationLock(calculationLockId, employerId);
    setDeleteButtonDisabled(false);
    onClose();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        icon={<Icon type="Trash" stroke="#ae2336" />}
        title={t('calculations:delete-calculation-lock-modal.title')}
        defaultClose
        onClose={onClose}
      >
        <Typography component="span">{t('calculations:delete-calculation-lock-modal.body')}</Typography>
        <CardAction>
          <Button type="link" label={t('common:cancel')} onClick={onClose} />
          <Button
            disabled={deleteButtonDisabled}
            type="warning"
            label={t('calculations:calculation-locks-drawer.delete-calculation-lock')}
            onClick={handleDeleteCalculationLock}
            data-cy="delete-calculation-lock-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default DeleteCalculationLockModal;
