import { EventValueEntry, InsuranceEvent } from 'src/types/reporting/InsuranceEvent';

export function insuranceEventIsPausable(insuranceEvent: InsuranceEvent): boolean {
  return insuranceEventStatusIsPausable(insuranceEvent);
}

export function insuranceEventStatusIsPausable(insuranceEvent: InsuranceEvent): boolean {
  switch (insuranceEvent.status) {
    case 'IDENTIFIED':
    case 'CHECKPOINT_PENDING':
    case 'READY_TO_REPORT':
    case 'NOT_REPORTED':
      return true;
    default:
      return false;
  }
}

export function insuranceEventIsDismissable(insuranceEvent: InsuranceEvent): boolean {
  return insuranceEventTypeIsDismissable(insuranceEvent) && insuranceEventStatusIsDismissable(insuranceEvent);
}

export function insuranceEventTypeIsDismissable(insuranceEvent: InsuranceEvent): boolean {
  switch (insuranceEvent.insuranceEventType) {
    case 'NEW_REGISTRATION_DUE_TO_EMPLOYMENT_STARTED':
    case 'NEW_REGISTRATION_DUE_TO_POLICY_SATISFIED':
    case 'NEW_REGISTRATION_DUE_TO_FULFILLED_WORKING_HOURS_LIMIT':
    case 'NEW_REGISTRATION_DUE_TO_BENEFIT_CHOICE_ITP1':
    case 'RENEW_REGISTRATION_AT_ORDINARY_RETIREMENT_AGE':
    case 'RE_EMPLOYMENT':
    case 'NEW_REGISTRATION_DUE_TO_PREPONED_START_DATE':
    case 'BACK_FROM_LEAVE_OF_ABSENCE':
    case 'BACK_FROM_PARENTAL_LEAVE':
    case 'BACK_FROM_MILITARY_SERVICE':
    case 'BACK_FROM_STUDIES_LEAVE':
    case 'REGISTRATION_OF_PGL':
    case 'REGISTRATION_OF_SPGL':
    case 'REGISTRATION_OF_KUB':
    case 'REGISTRATION_OF_LOCKED_KUB':
    case 'BACK_FROM_SICK_LEAVE':
    case 'REPORTING_SICK_DUE_TO_LONG_TERM_SICK':
    case 'REPORTING_SICK_DUE_TO_ALT_SICK_RULE':
    case 'REPORTING_SICK_DUE_TO_SICK_AND_ACTIVITY_COMPENSATION':
    case 'END_REGISTRATION_DUE_TO_RETIREMENT_ON_INDIVIDUAL_AGE':
    case 'END_REGISTRATION_DUE_TO_EMPLOYMENT_CEASES':
    case 'END_REGISTRATION_DUE_TO_EMPLOYMENT_CEASES_DURING_ABSENCE':
    case 'END_REGISTRATION_DUE_TO_BENEFIT_CHOICE_ITP1':
    case 'END_REGISTRATION_DUE_TO_LEAVE_OF_ABSENCE':
    case 'END_REGISTRATION_DUE_TO_PARENTAL_LEAVE':
    case 'END_REGISTRATION_DUE_TO_MILITARY_SERVICE':
    case 'END_REGISTRATION_DUE_TO_POLICY_NOT_SATISFIED':
    case 'END_REGISTRATION_DUE_TO_STUDIES':
    case 'END_REGISTRATION_DUE_TO_BELOW_WORKING_HOURS_LIMIT':
      return true;
    default:
      return false;
  }
}

export function insuranceEventStatusIsDismissable(insuranceEvent: InsuranceEvent): boolean {
  switch (insuranceEvent.status) {
    case 'IDENTIFIED':
    case 'CHECKPOINT_PENDING':
    case 'READY_TO_REPORT':
    case 'EXTERNALLY_REPORTED':
    case 'NOT_REPORTED':
      return true;
    default:
      return false;
  }
}

export function insuranceEventStatusIsPossibleToMarkAsExternallyReported(insuranceEvent: InsuranceEvent): boolean {
  switch (insuranceEvent.status) {
    case 'IDENTIFIED':
    case 'CHECKPOINT_PENDING':
    case 'READY_TO_REPORT':
    case 'NOT_REPORTED':
      return true;
    case 'REPORTED':
    case 'TO_BE_DISMISSED':
    case 'DISMISSED_FINISHED':
    case 'EXTERNALLY_REPORTED_FINISHED':
    case 'EXTERNALLY_REPORTED':
    default:
      return false;
  }
}

export function insuranceEventIsPossibleToMarkAsExternallyReported(insuranceEvent: InsuranceEvent) {
  return insuranceEventStatusIsPossibleToMarkAsExternallyReported(insuranceEvent);
}

export function getCorrectEventValue(eventValue: EventValueEntry) {
  if (eventValue.currentValue === null && eventValue.eventValueType === 'SPGL') {
    return 0;
  } else {
    return eventValue.currentValue;
  }
}
