import { Button, Collapsible, Icon, InlineEdit, ListGroup, Option, Pill, Select } from 'fgirot-k2-ui-components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import { CreateCalculationLockInput } from 'src/types/employees/request/CreateCalculationLockInput';
import { useCreateCalculationLock } from 'src/service/employeecard/CreateCalculationLock';
import { useYearOptions } from 'src/util/date/Years';
import { convertMonthEnumToStringWithZeroes, useMonthOptions } from 'src/util/date/Months';
import { Month } from 'src/types/Month';
import { useEmployerKubs } from 'src/service/employer/GetEmployerKubs';
import { usePglRules } from 'src/service/policy/GetPglRules';
import { createOptionsFromList } from 'src/util/OptionUtil';
import When from 'src/components/Common/When';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import { getValidPeriodInput } from '../utils';
import './create-draft-calculation-lock-drawer.scss';

interface CreateDraftCalculationLockDrawerProps {
  periodText: string;
  employeeId: string;
  employerId: string;
  employeeCardId: string;
  onBackClick: () => void;
  onClose: () => void;
  policyEntities: PolicyEntity[];
}

const CreateDraftCalculationLockDrawer: FC<CreateDraftCalculationLockDrawerProps> = ({
  periodText,
  employeeId,
  employerId,
  employeeCardId,
  onBackClick,
  onClose,
  policyEntities,
}) => {
  const { t } = useTranslation();
  const [selectedFromMonth, setSelectedFromMonth] = useState<Option<Month>>({ label: t('common:month'), value: null });
  const [selectedFromYear, setSelectedFromYear] = useState<Option<number>>({ label: t('common:year'), value: null });
  const [selectedToMonth, setSelectedToMonth] = useState<Option<Month>>({ label: t('common:month'), value: null });
  const [selectedToYear, setSelectedToYear] = useState<Option<number>>({ label: t('common:year'), value: null });
  const [fromPeriodInput, setFromPeriodInput] = useState<string>(undefined);
  const [toPeriodInput, setToPeriodInput] = useState<string>(undefined);
  const [createButtonDisabled, setCreateButtonDisabled] = useState<boolean>(true);

  const yearsOptions = useYearOptions(true);
  const monthOptions = useMonthOptions(true);

  const calculationTypeOptions: Option<string>[] = [
    { value: 'PGL', label: 'PGL' },
    { value: 'KUB', label: 'KUB' },
  ];
  const [selectedCalculationType, setSelectedCalculationType] = useState<Option<string>>(null);
  const handleCalculationTypeChange = (option: Option<string>) => {
    setSelectedCalculationType(option);
    setSelectedKubType(null);
    setSelectedPglRule(null);
  };

  const activePolicyId = policyEntities.find((policy) => policy.state === 'ACTIVE').id;

  const pglRules = usePglRules(activePolicyId);
  const pglRuleOptions = createOptionsFromList(pglRules, 'ruleId', 'name');
  const [selectedPglRule, setSelectedPglRule] = useState<Option<string>>(null);
  const handlePglRuleChange = (option: Option<string>) => {
    setSelectedPglRule(option);
  };

  const employerKubs = useEmployerKubs(employerId);
  const kubTypeOptions = createOptionsFromList(employerKubs, 'kubType', 'kubType');
  const [selectedKubType, setSelectedKubType] = useState<Option<string>>(null);
  const handleKubTypeChange = (option: Option<string>) => {
    setSelectedKubType(option);
  };

  const createCalculationLock = useCreateCalculationLock();

  const handleFromPeriodInputChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedFromYear(yearOption);
    setSelectedFromMonth(monthOption);

    if (monthOption && yearOption) {
      const period = yearOption.label + convertMonthEnumToStringWithZeroes(monthOption.value);
      setFromPeriodInput(period);
    }
  };

  const handleToPeriodInputChange = (monthOption: Option<Month>, yearOption: Option<number>) => {
    setSelectedToYear(yearOption);
    setSelectedToMonth(monthOption);

    if (monthOption && yearOption) {
      const period = yearOption.label + convertMonthEnumToStringWithZeroes(monthOption.value);
      setToPeriodInput(period);
    }
  };

  const handleCreateCalculationLock = async () => {
    setCreateButtonDisabled(true);

    const request: CreateCalculationLockInput = {
      employeeId: employeeId,
      fromPeriod: fromPeriodInput,
      toPeriod: getValidPeriodInput(toPeriodInput),
      pglPolicyRuleId: selectedPglRule?.value ? selectedPglRule?.value : null,
      kubType: selectedKubType?.value ? selectedKubType?.value : null,
      employeeCardId: employeeCardId,
      deleted: false,
      processed: false,
      isDraft: true,
    };
    await createCalculationLock(employerId, request);
    setCreateButtonDisabled(false);
    onBackClick();
  };

  useEffect(() => {
    if (
      (!selectedCalculationType?.value && !selectedPglRule?.value) ||
      (!selectedCalculationType?.value && !selectedKubType?.value) ||
      (selectedCalculationType?.value &&
        !selectedPglRule?.value &&
        selectedCalculationType &&
        !selectedKubType?.value) ||
      !fromPeriodInput ||
      !selectedFromMonth?.value ||
      !selectedFromYear?.value ||
      (!selectedToMonth?.value && selectedToYear?.value) ||
      (selectedToMonth?.value && !selectedToYear?.value)
    ) {
      setCreateButtonDisabled(true);
    } else {
      setCreateButtonDisabled(false);
    }
  }, [
    selectedCalculationType?.value,
    selectedPglRule?.value,
    selectedKubType?.value,
    fromPeriodInput,
    selectedFromMonth?.value,
    selectedFromYear?.value,
    selectedToMonth?.value,
    selectedToYear?.value,
  ]);

  return (
    <DrawerBase
      title={t('calculations:calculation-locks-drawer.add-calculation-lock')}
      subTitle={<Pill type="success" label={periodText} />}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <div className="create-draft-calculation-lock-drawer">
        <EmployeeInDraftBanner />
        <ListGroup className="create-draft-calculation-lock-drawer" variant="inline-edit">
          <InlineEdit label={t('calculations:calculation-locks-drawer.calculation-type-label')} bold>
            <Select
              placeholder={t('calculations:calculation-locks-drawer.calculation-type-placeholder')}
              options={calculationTypeOptions}
              onChange={(option: Option<string>) => handleCalculationTypeChange(option)}
              selected={selectedCalculationType}
              alphabetical={false}
              data-testid="dropdown-select-from-month"
              small
            />
          </InlineEdit>
        </ListGroup>
        <Collapsible
          className="create-draft-calculation-lock-drawer__collapsible-list-group"
          open={selectedCalculationType?.value === 'PGL' || selectedCalculationType?.value === 'KUB'}
        >
          <When condition={selectedCalculationType?.value === 'PGL'}>
            <ListGroup variant="inline-edit">
              <InlineEdit label={t('calculations:pgl')} bold>
                <Select
                  placeholder={t('calculations:calculation-locks-drawer.pgl-placeholder')}
                  options={pglRuleOptions}
                  onChange={(option: Option<string>) => handlePglRuleChange(option)}
                  selected={pglRuleOptions.find((option) => option?.value === selectedPglRule?.value)}
                  alphabetical={false}
                  data-testid="dropdown-select-from-month"
                  small
                />
              </InlineEdit>
            </ListGroup>
          </When>
          <When condition={selectedCalculationType?.value === 'KUB'}>
            <ListGroup variant="inline-edit">
              <InlineEdit label={t('calculations:kub')} bold>
                <Select
                  placeholder={t('calculations:calculation-locks-drawer.kub-placeholder')}
                  options={kubTypeOptions}
                  onChange={(option: Option<string>) => handleKubTypeChange(option)}
                  selected={kubTypeOptions.find((option) => option?.value === selectedKubType?.value)}
                  alphabetical={false}
                  data-testid="dropdown-select-from-month"
                  small
                />
              </InlineEdit>
            </ListGroup>
          </When>
        </Collapsible>

        <ListGroup className="create-draft-calculation-lock-drawer__period-list-group" variant="inline-edit">
          <InlineEdit label={t('calculations:calculation-locks-drawer.from-period-label')} bold>
            <div className="create-draft-calculation-lock-drawer__period-input">
              <Select
                placeholder={t('common:month')}
                options={monthOptions}
                onChange={(option: Option<Month>) => handleFromPeriodInputChange(option, selectedFromYear)}
                selected={selectedFromMonth}
                alphabetical={false}
                data-testid="dropdown-select-from-month"
                small
              />
              <Select
                placeholder={t('common:year')}
                options={yearsOptions}
                onChange={(option: Option<number>) => handleFromPeriodInputChange(selectedFromMonth, option)}
                selected={selectedFromYear}
                data-testid="dropdown-select-from-year"
                small
              />
            </div>
          </InlineEdit>
          <InlineEdit label={t('calculations:calculation-locks-drawer.to-period-label')} bold>
            <div className="create-draft-calculation-lock-drawer__period-input">
              <Select
                placeholder={t('common:month')}
                options={monthOptions}
                onChange={(option: Option<Month>) => handleToPeriodInputChange(option, selectedToYear)}
                selected={selectedToMonth}
                alphabetical={false}
                data-testid="dropdown-select-to-month"
                small
              />
              <Select
                placeholder={t('common:year')}
                options={yearsOptions}
                onChange={(option: Option<number>) => handleToPeriodInputChange(selectedToMonth, option)}
                selected={selectedToYear}
                data-testid="dropdown-select-to-year"
                small
              />
            </div>
          </InlineEdit>
        </ListGroup>
        <Button
          className="create-draft-calculation-lock-drawer__button"
          type="primary"
          label={t('calculations:calculation-locks-drawer.add-calculation-lock')}
          onClick={handleCreateCalculationLock}
          disabled={createButtonDisabled}
          data-cy="add-calculation-lock-button"
        />
      </div>
    </DrawerBase>
  );
};

export default CreateDraftCalculationLockDrawer;
