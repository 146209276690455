import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import DrawerBase from 'src/components/Common/DrawerBase/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { EmployeeCardCalculation } from 'src/types/employees/EmployeeCardCalculation';
import KubDetailsDrawer from '../KubDetailsDrawer/KubDetailsDrawer';
import PglDetailsDrawer from '../PglDetailsDrawer/PglDetailsDrawer';
import { useTranslation } from 'react-i18next';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import CalculationAboutDrawerTab from '../CalculationAboutDrawerTab';
import { CalculationsOverviewDrawerTab } from 'src/types/tabs/CalculationsOverviewDrawerTab';
import CalculationLocksDrawerTab from '../CalculationLocksDrawerTab';
import { CalculationLock } from 'src/types/employees/CalculationLock';
import CalculationLockDetailsDrawer from '../CalculationLockDetailsDrawer';
import When from 'src/components/Common/When';
import CreateDraftCalculationLockDrawer from '../CreateDraftCalculationLockDrawer';
import { Employee } from 'src/types/Employee';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';

interface CalculationsOverviewDrawerProps {
  open: boolean;
  onClose: () => void;
  onBackToAboutTab: () => void;
  onBackToCalculationLockTab: () => void;
  period: string;
  selectedPglId: string;
  selectedKubId: string;
  selectedCalculationLockId: string;
  isCreatingCalculationLock: boolean;
  onDisplayPglDetailsDrawer: (id: string) => void;
  onDisplayKubDetailsDrawer: (id: string) => void;
  onDisplayCalculationLockDetailsDrawer: (id: string) => void;
  onDisplayCreateCalculationLockDrawer: () => void;
  qualifiedCalculations: EmployeeCardCalculation;
  employerId: string;
  employee: Employee;
  policyEntities: PolicyEntity[];
  calculationLocks: CalculationLock[];
  hasChangesInDraft: boolean;
  currentTab: CalculationsOverviewDrawerTab;
  setCurrentTab: React.Dispatch<React.SetStateAction<CalculationsOverviewDrawerTab>>;
}

const CalculationsOverviewDrawer = ({
  open,
  onClose,
  onBackToAboutTab,
  onBackToCalculationLockTab,
  period,
  selectedPglId,
  selectedKubId,
  selectedCalculationLockId,
  isCreatingCalculationLock,
  onDisplayPglDetailsDrawer,
  onDisplayKubDetailsDrawer,
  onDisplayCalculationLockDetailsDrawer,
  onDisplayCreateCalculationLockDrawer,
  qualifiedCalculations,
  employerId,
  employee,
  policyEntities,
  calculationLocks,
  hasChangesInDraft,
  currentTab,
  setCurrentTab,
}: CalculationsOverviewDrawerProps) => {
  const { t } = useTranslation();
  const periodText = getFormattedDateStringFromPeriod(period);

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      {!selectedKubId && !selectedPglId && !selectedCalculationLockId && !isCreatingCalculationLock && (
        <DrawerBase
          title={
            <>
              <Typography variant="h2" bold>
                {t('calculations:calculations')}
              </Typography>
              <Pill type="success" label={periodText} />
            </>
          }
          onClose={onClose}
        >
          <Tabs tabIndex={currentTab} onChange={setCurrentTab}>
            <Tab title={t('calculations:calculations-drawer.tab-title')}>
              <CalculationAboutDrawerTab
                qualifiedCalculations={qualifiedCalculations}
                onDisplayPglDetailsDrawer={onDisplayPglDetailsDrawer}
                onDisplayKubDetailsDrawer={onDisplayKubDetailsDrawer}
              />
            </Tab>
            <Tab title={t('calculations:calculation-locks-drawer.tab-title')}>
              <CalculationLocksDrawerTab
                calculationLocks={calculationLocks}
                onDisplayCalculationLockDetailsDrawer={onDisplayCalculationLockDetailsDrawer}
                onDisplayCreateCalculationLockDrawer={onDisplayCreateCalculationLockDrawer}
                isDraft={employee.isDraft}
                hasChangesInDraft={hasChangesInDraft}
              />
            </Tab>
          </Tabs>
        </DrawerBase>
      )}
      <When condition={selectedKubId && !selectedPglId && !selectedCalculationLockId && !isCreatingCalculationLock}>
        <KubDetailsDrawer
          onClose={onClose}
          onBackClick={onBackToAboutTab}
          kubId={selectedKubId}
          periodText={periodText}
        />
      </When>
      <When condition={selectedPglId && !selectedKubId && !selectedCalculationLockId && !isCreatingCalculationLock}>
        <PglDetailsDrawer
          onClose={onClose}
          onBackClick={onBackToAboutTab}
          pglId={selectedPglId}
          periodText={periodText}
          employerId={employerId}
        />
      </When>
      {selectedCalculationLockId && !selectedPglId && !selectedKubId && !isCreatingCalculationLock && (
        <CalculationLockDetailsDrawer
          periodText={periodText}
          employerId={employerId}
          onClose={onClose}
          onBackClick={onBackToCalculationLockTab}
          calculationLock={calculationLocks.find((selected) => selected.id === selectedCalculationLockId)}
          isDraft={employee.isDraft}
        />
      )}
      <When
        condition={
          employee.isDraft &&
          isCreatingCalculationLock &&
          !selectedCalculationLockId &&
          !selectedPglId &&
          !selectedKubId
        }
      >
        <CreateDraftCalculationLockDrawer
          periodText={periodText}
          employeeId={employee?.id}
          employerId={employerId}
          employeeCardId={qualifiedCalculations?.employeeCardId}
          onBackClick={onBackToCalculationLockTab}
          onClose={onClose}
          policyEntities={policyEntities}
        />
      </When>
    </DrawerModal>
  );
};

export default CalculationsOverviewDrawer;
