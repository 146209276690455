import { Banner, Icon, InteractiveCard, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmployeeSectionLabel from '../../EmployeeSectionLabel';
import { useTranslation } from 'react-i18next';
import InteractiveCardBox from 'src/components/Common/InteractiveCardBox';
import CalculationsOverviewDrawer from '../CalculationsOverviewDrawer';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_QUALIFIED_CALCULATIONS } from 'src/graphql/schema/query/employee/GetQualifiedCalculations';
import { EmployeeCardCalculation } from 'src/types/employees/EmployeeCardCalculation';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import CalculationLabel from 'src/components/Employees/EmployeeOverview/calculations/CalculationLabel';
import When from 'src/components/Common/When';
import { Employee } from 'src/types/Employee';
import { usePolicyEntities } from 'src/service/policy/GetPolicyEntities';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import LoadingPage from 'src/components/Common/LoadingPage';
import { CalculationLock } from 'src/types/employees/CalculationLock';
import { CalculationsOverviewDrawerTab } from 'src/types/tabs/CalculationsOverviewDrawerTab';
import './calculations-overview-section.scss';

interface CalculationsOverviewSectionProps {
  employeeCard: AggregatedEmployeeCard;
  employee: Employee;
  employerId: string;
  calculationLocks: CalculationLock[];
}

const CalculationsOverviewSection: FC<CalculationsOverviewSectionProps> = ({
  employeeCard,
  employee,
  employerId,
  calculationLocks,
}) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPglId, setSelectedPglId] = useState<string>(null);
  const [selectedKubId, setSelectedKubId] = useState<string>(null);
  const [selectedCalculationLockId, setSelectedCalculationLockId] = useState<string>(null);
  const [isCreatingCalculationLock, setIsCreatingCalculationLock] = useState(false);
  const [currentTab, setCurrentTab] = useState<CalculationsOverviewDrawerTab>(CalculationsOverviewDrawerTab.ABOUT);

  const { period } = useParams();

  const handleDisplayPglDetailsDrawer = (id: string) => {
    setDrawerOpen(true);
    setSelectedPglId(id);
    setSelectedKubId(null);
    setIsCreatingCalculationLock(false);
    setSelectedCalculationLockId(null);
  };

  const handleDisplayKubDetailsDrawer = (id: string) => {
    setDrawerOpen(true);
    setSelectedKubId(id);
    setSelectedPglId(null);
    setIsCreatingCalculationLock(false);
    setSelectedCalculationLockId(null);
  };

  const handleDisplayCalculationLockDetailsDrawer = (id: string) => {
    setDrawerOpen(true);
    setSelectedCalculationLockId(id);
    setIsCreatingCalculationLock(false);
    setSelectedKubId(null);
    setSelectedPglId(null);
    setCurrentTab(CalculationsOverviewDrawerTab.CALCULATION_LOCKS);
  };

  const handleDisplayCreateCalculationLockDrawer = () => {
    setDrawerOpen(true);
    setIsCreatingCalculationLock(true);
    setSelectedCalculationLockId(null);
    setSelectedPglId(null);
    setSelectedKubId(null);
  };

  const handleDisplayOverviewDrawer = () => {
    setDrawerOpen(true);
    setCurrentTab(CalculationsOverviewDrawerTab.ABOUT);
    setIsCreatingCalculationLock(false);
    setSelectedCalculationLockId(null);
    setSelectedPglId(null);
    setSelectedKubId(null);
  };

  const handleOnClose = () => {
    setDrawerOpen(false);
    setSelectedKubId(null);
    setSelectedPglId(null);
    setIsCreatingCalculationLock(false);
    setSelectedCalculationLockId(null);
  };

  const handleBackToAboutTab = () => {
    setSelectedPglId(null);
    setSelectedKubId(null);
    setIsCreatingCalculationLock(false);
    setSelectedCalculationLockId(null);
    setCurrentTab(CalculationsOverviewDrawerTab.ABOUT);
  };

  const handleBackToCalculationLockTab = () => {
    setSelectedPglId(null);
    setSelectedKubId(null);
    setIsCreatingCalculationLock(false);
    setSelectedCalculationLockId(null);
    setCurrentTab(CalculationsOverviewDrawerTab.CALCULATION_LOCKS);
  };

  const service = usePolicyEntities(employerId);

  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>Error...</div>;
    case LOADED:
      break;
  }

  const policyEntities = service.payload;

  const hasChangesInDraft = calculationLocks.some((lock) => lock.isDraft);

  return (
    <>
      <InteractiveCard
        label={
          <EmployeeSectionLabel
            label={t('calculations:calculations')}
            period={period}
            hasChangesInDraft={hasChangesInDraft}
          />
        }
        onClick={handleDisplayOverviewDrawer}
        data-cy="calculations-overview-section__calculations"
        disabledDrawer={!employeeCard?.id}
      >
        {!employeeCard?.id ? (
          <Banner type="announcement" message={t('calculations:no-qualified-calculations')} />
        ) : (
          <QueryWrapper
            query={GET_QUALIFIED_CALCULATIONS}
            options={{ variables: { employeeCardId: employeeCard?.id } }}
          >
            {(qualifiedCalculations: EmployeeCardCalculation) => {
              return (
                <>
                  <When
                    condition={
                      qualifiedCalculations.pglOutcomes.length === 0 && qualifiedCalculations.kubOutcomes.length === 0
                    }
                  >
                    <Banner type="announcement" message={t('calculations:no-qualified-calculations')} />
                  </When>
                  <div className="calculations-overview-section">
                    <div className="calculations-overview-section__boxes" data-cy="calculations-overview-section__pgl">
                      {qualifiedCalculations.pglOutcomes.slice(0, 3).map((pglOutcome, index) => (
                        <InteractiveCardBox
                          className="calculations-overview-section__box"
                          key={pglOutcome.id}
                          label={<CalculationLabel label={pglOutcome.name} type={pglOutcome.pglType} />}
                          value={
                            <>
                              <When condition={!pglOutcome.lockedPglSum}>
                                <>{formatSweAmountTextWithSuffixNoDecimal(pglOutcome.pglSum)}</>
                              </When>
                              <When condition={pglOutcome.lockedPglSum !== null}>
                                <div className="calculations-overview-section__value">
                                  <div className="calculations-overview-section__locked-sum">
                                    <Icon type="Lock" />
                                    {formatSweAmountTextWithSuffixNoDecimal(pglOutcome.lockedPglSum)}
                                  </div>
                                  <div className="calculations-overview-section__previous-sum">
                                    {'(' + formatSweAmountTextWithSuffixNoDecimal(pglOutcome.pglSum) + ')'}
                                  </div>
                                </div>
                              </When>
                            </>
                          }
                          onClick={() => handleDisplayPglDetailsDrawer(pglOutcome.id)}
                          index={index}
                        />
                      ))}
                      {qualifiedCalculations.pglOutcomes.length > 3 && (
                        <InteractiveCardBox
                          label={
                            <Typography variant="body2" bold>{`+${
                              qualifiedCalculations.pglOutcomes.length - 3
                            }`}</Typography>
                          }
                          onClick={handleDisplayOverviewDrawer}
                        />
                      )}
                    </div>
                    <div className="calculations-overview-section__boxes" data-cy="calculations-overview-section__kub">
                      {qualifiedCalculations.kubOutcomes.slice(0, 3).map((kubOutcome, index) => (
                        <InteractiveCardBox
                          className="calculations-overview-section__box"
                          key={kubOutcome.id}
                          label={<CalculationLabel label={kubOutcome.kubType} type={'KUB'} />}
                          value={
                            <>
                              <When condition={!kubOutcome?.lockedKubSum}>
                                <>{formatSweAmountTextWithSuffixNoDecimal(kubOutcome.kubSum)}</>
                              </When>
                              <When condition={kubOutcome?.lockedKubSum !== null}>
                                <div className="calculations-overview-section__value">
                                  <div className="calculations-overview-section__locked-sum">
                                    <Icon type="Lock" />
                                    {formatSweAmountTextWithSuffixNoDecimal(kubOutcome.lockedKubSum)}
                                  </div>
                                  <div className="calculations-overview-section__previous-sum">
                                    {'(' + formatSweAmountTextWithSuffixNoDecimal(kubOutcome.kubSum) + ')'}
                                  </div>
                                </div>
                              </When>
                            </>
                          }
                          onClick={() => handleDisplayKubDetailsDrawer(kubOutcome.id)}
                          index={index}
                        />
                      ))}
                      {qualifiedCalculations.kubOutcomes.length > 3 && (
                        <InteractiveCardBox
                          label={
                            <Typography variant="body2" bold>{`+${
                              qualifiedCalculations.kubOutcomes.length - 3
                            }`}</Typography>
                          }
                          onClick={handleDisplayOverviewDrawer}
                        />
                      )}
                    </div>
                  </div>
                  {drawerOpen && (
                    <CalculationsOverviewDrawer
                      period={period}
                      open={drawerOpen}
                      onClose={handleOnClose}
                      onBackToAboutTab={handleBackToAboutTab}
                      onBackToCalculationLockTab={handleBackToCalculationLockTab}
                      onDisplayPglDetailsDrawer={handleDisplayPglDetailsDrawer}
                      onDisplayKubDetailsDrawer={handleDisplayKubDetailsDrawer}
                      onDisplayCalculationLockDetailsDrawer={handleDisplayCalculationLockDetailsDrawer}
                      onDisplayCreateCalculationLockDrawer={handleDisplayCreateCalculationLockDrawer}
                      selectedKubId={selectedKubId}
                      selectedPglId={selectedPglId}
                      selectedCalculationLockId={selectedCalculationLockId}
                      isCreatingCalculationLock={isCreatingCalculationLock}
                      qualifiedCalculations={qualifiedCalculations}
                      employerId={employerId}
                      employee={employee}
                      policyEntities={policyEntities}
                      calculationLocks={calculationLocks}
                      hasChangesInDraft={hasChangesInDraft}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                    />
                  )}
                </>
              );
            }}
          </QueryWrapper>
        )}
      </InteractiveCard>
    </>
  );
};

export default CalculationsOverviewSection;
