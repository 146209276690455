import { SearchAccountingItemFilters } from 'src/types/ledgerStorage/SearchAccountingItemFilters';

export const getDefaultAccountItemFilters = (): SearchAccountingItemFilters => {
  return {
    searchInput: '',
    accountingPeriod: {
      fromPeriod: '',
      toPeriod: '',
    },
    premiumPeriod: {
      fromPeriod: '',
      toPeriod: '',
    },
    insuranceProviderIds: [],
    employerId: null,
    accountingTypeValue: [],
    status: [],
  };
};
