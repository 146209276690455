import { useApolloMutation } from '../graphql/Mutation';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { GET_QUALIFIED_CALCULATIONS } from 'src/graphql/schema/query/employee/GetQualifiedCalculations';
import { UPDATE_CALCULATION_LOCK } from 'src/graphql/schema/mutation/employeecard/UpdateCalculationLock';
import { PatchCalculationLockInput } from 'src/types/employees/request/PatchCalculationLockInput';
import { GET_CALCULATION_LOCKS } from 'src/graphql/schema/query/employee/GetCalculationLocks';

export const useUpdateCalculationLock = () => {
  const apolloMutation = useApolloMutation();

  const updateCalculationLock = (employerId: string, request: PatchCalculationLockInput, useDraft: boolean) =>
    apolloMutation(
      UPDATE_CALCULATION_LOCK,
      employerId,
      {
        request,
        useDraft,
      },
      [GET_EMPLOYEE, GET_CALCULATION_LOCKS, GET_QUALIFIED_CALCULATIONS],
    );

  return updateCalculationLock;
};
