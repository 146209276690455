import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DrawerNavigation, Icon, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import DrawerBase from 'src/components/Common/DrawerBase';
import { PglBonus, PglOutcome, PglWage } from 'src/types/employees/PglOutcome';
import { formatSweAmountText, formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import BonusOverviewDrawer from './BonusOverviewDrawer';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_PGL_OUTCOME } from 'src/graphql/schema/query/employee/GetPglOutCome';
import PglWageTypeDetailsDrawer from './PglWageTypeDetailsDrawer';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import DetailsDrawerTitle from 'src/components/Employees/EmployeeOverview/calculations/DetailsDrawerTitle';
import When from 'src/components/Common/When';
import './pgl-details-drawer.scss';

interface PglDetailsDrawerProps {
  onClose: () => void;
  onBackClick: () => void;
  pglId: string;
  periodText: string;
  employerId: string;
}

export const PglDetailsDrawer: FC<PglDetailsDrawerProps> = ({
  onClose,
  onBackClick,
  pglId,
  periodText,
  employerId,
}) => {
  const { t } = useTranslation();
  const [selectedPglWage, setSelectedPglWage] = React.useState<PglWage>(null);
  const [selectedPglBonus, setSelectedPglBonus] = React.useState<PglBonus>(null);
  const employerWageTypes = useEmployerWageTypes(employerId);

  const handleOnBackClickPglWage = () => setSelectedPglWage(null);
  const handleOnBackClickBonus = () => setSelectedPglBonus(null);

  const handleDisplayPglWageTypeDetailsDrawer = (pglWage: PglWage) => setSelectedPglWage(pglWage);
  const handleDisplayBonusOverviewDrawer = (pglBonus: PglBonus) => setSelectedPglBonus(pglBonus);

  return (
    <QueryWrapper query={GET_PGL_OUTCOME} options={{ variables: { pglId } }}>
      {(pglOutcome: PglOutcome) => {
        return (
          <>
            {!selectedPglBonus && !selectedPglWage && (
              <DrawerBase
                title={
                  <DetailsDrawerTitle
                    period={periodText}
                    name={pglOutcome.name}
                    type={pglOutcome.pglType}
                    locked={!!pglOutcome.lockedPglSum}
                  />
                }
                leftButton={
                  <Button
                    type="link"
                    label={t('common:back')}
                    icon={<Icon type={'ArrowLeft'} />}
                    onClick={onBackClick}
                  />
                }
                onClose={onClose}
              >
                <div className="pgl-details-drawer">
                  <ListGroup variant="drawer-navigation">
                    <>
                      {pglOutcome.pglWages.map((pglWage, index) => {
                        const employerWageType = employerWageTypes.find(
                          (employerWageType) => employerWageType.id === pglWage.employerWageTypeId,
                        );
                        const denominationTitle = pglWage.conversionDenominator
                          ? ` / ${pglWage.conversionDenominator}`
                          : '';
                        return (
                          pglWage.includedWages.length !== 0 && (
                            <DrawerNavigation
                              key={`PglDetailsDrawer-${employerWageType.wageType}-${index}`}
                              label={
                                t(`wageTypes:${employerWageType.wageType}`) +
                                ` * ${pglWage.conversionFactor}` +
                                denominationTitle
                              }
                              value={formatSweAmountText(pglWage.pglSum.toString()) + 'kr'}
                              onClick={() => handleDisplayPglWageTypeDetailsDrawer(pglWage)}
                            />
                          )
                        );
                      })}
                    </>
                    <>
                      {pglOutcome.pglBonuses.map((pglBonus, index) => {
                        const employerWageTypeName = employerWageTypes.find(
                          (employerWageType) => employerWageType.id === pglBonus.employerWageTypeId,
                        )?.name;
                        return (
                          <DrawerNavigation
                            key={`PglDetailsDrawer-${pglBonus.bonusAverageType}-${index}`}
                            label={employerWageTypeName}
                            value={formatSweAmountText(pglBonus.bonusAverageAmount.toString()) + 'kr'}
                            onClick={() => handleDisplayBonusOverviewDrawer(pglBonus)}
                          />
                        );
                      })}
                    </>
                  </ListGroup>
                  <ListGroup variant="inline-edit">
                    <InlineEdit bold label={t('calculations:sum')}>
                      <When condition={pglOutcome?.lockedPglSum !== null}>
                        <div className="pgl-details-drawer--locked-sum">
                          <Icon type="Lock" />
                          <Typography variant="body2">
                            {formatSweAmountTextWithSuffixNoDecimal(pglOutcome?.lockedPglSum)}
                          </Typography>
                        </div>
                        <div className="pgl-details-drawer--text">
                          <Typography variant="body2">
                            {`(${formatSweAmountTextWithSuffixNoDecimal(pglOutcome.pglSum)})`}
                          </Typography>
                        </div>
                      </When>
                      <When condition={!pglOutcome?.lockedPglSum}>
                        <Typography variant="body2">
                          {formatSweAmountTextWithSuffixNoDecimal(pglOutcome.pglSum)}
                        </Typography>
                      </When>
                    </InlineEdit>
                  </ListGroup>
                </div>
              </DrawerBase>
            )}
            {selectedPglWage && (
              <PglWageTypeDetailsDrawer
                onClose={onClose}
                onBackClick={handleOnBackClickPglWage}
                pglWage={selectedPglWage}
                employerWageTypes={employerWageTypes}
              />
            )}
            {selectedPglBonus && (
              <BonusOverviewDrawer
                onClose={onClose}
                onBackClick={handleOnBackClickBonus}
                pglBonus={selectedPglBonus}
                employerWageType={employerWageTypes.find(
                  (employerWageType) => employerWageType.id === selectedPglBonus.employerWageTypeId,
                )}
              />
            )}
          </>
        );
      }}
    </QueryWrapper>
  );
};
export default PglDetailsDrawer;
