import { useApolloMutation } from '../graphql/Mutation';
import { GET_QUALIFIED_CALCULATIONS } from 'src/graphql/schema/query/employee/GetQualifiedCalculations';
import { DELETE_CALCULATION_LOCK } from 'src/graphql/schema/mutation/employeecard/DeleteCalculationLock';
import { GET_EMPLOYEE } from 'src/graphql/schema/query/employee/GetEmployee';
import { GET_CALCULATION_LOCKS } from 'src/graphql/schema/query/employee/GetCalculationLocks';

export const useDeleteCalculationLock = () => {
  const apolloMutation = useApolloMutation();

  const deleteCalculationLock = (calculationLockId: string, employerId: string) =>
    apolloMutation(DELETE_CALCULATION_LOCK, employerId, { calculationLockId }, [
      GET_EMPLOYEE,
      GET_CALCULATION_LOCKS,
      GET_QUALIFIED_CALCULATIONS,
    ]);

  return deleteCalculationLock;
};
