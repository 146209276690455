import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeadProps } from 'src/types/TableHeadProps';
import { AccountingItemsTableSortField } from './AccountingItemsTableSortField';

interface AccountingItemsTableHeadsProps {
  sortDirection: SortDirection;
  sortField: AccountingItemsTableSortField;
  onApplySorting(property: AccountingItemsTableSortField);
  accountingComponentTypes: string[];
}

const AccountingItemsTableHeads: FC<AccountingItemsTableHeadsProps> = ({
  sortDirection,
  sortField,
  onApplySorting,
  accountingComponentTypes,
}) => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      title: t('economy:accounting-items-tab.table.head.first-name'),
      fieldName: 'firstName',
    },
    {
      title: t('economy:accounting-items-tab.table.head.last-name'),
      fieldName: 'lastName',
    },
    {
      title: t('economy:accounting-items-tab.table.head.person-number'),
      fieldName: 'personNumber',
    },
    {
      title: t('economy:accounting-items-tab.table.head.accounting-period'),
      sortDirection,
      fieldName: 'accountingPeriod',
      handleSortDirectionChange: () => {
        onApplySorting('accountingPeriod');
      },
      includeSortButtons: true,
    },
    {
      title: t('economy:accounting-items-tab.table.head.premium-period'),
      sortDirection,
      fieldName: 'premiumPeriod',
      handleSortDirectionChange: () => {
        onApplySorting('premiumPeriod');
      },
      includeSortButtons: true,
    },
    {
      title: t('economy:accounting-items-tab.table.head.accounting-status'),
      sortDirection,
      fieldName: 'status',
      handleSortDirectionChange: () => {
        onApplySorting('status');
      },
      includeSortButtons: true,
    },
    {
      title: t('economy:accounting-items-tab.table.head.accounting-number'),
      sortDirection,
      fieldName: 'accountingNumber',
      handleSortDirectionChange: () => {
        onApplySorting('accountingNumber');
      },
      includeSortButtons: true,
    },
    {
      title: t('economy:accounting-items-tab.table.head.accounting-type'),
      sortDirection,
      fieldName: 'accountingTypeValue',
      handleSortDirectionChange: () => {
        onApplySorting('accountingTypeValue');
      },
      includeSortButtons: true,
    },
    ...accountingComponentTypes.map((componentType) => ({
      title: componentType,
      fieldName: componentType,
    })),
    {
      title: t('economy:accounting-items-tab.table.head.amount'),
      sortDirection,
      fieldName: 'amount',
      handleSortDirectionChange: () => {
        onApplySorting('amount');
      },
      includeSortButtons: true,
    },
    {
      title: t('economy:accounting-items-tab.table.head.invoice-number'),
      sortDirection,
      fieldName: 'invoiceNumber',
      handleSortDirectionChange: () => {
        onApplySorting('invoiceNumber');
      },
      includeSortButtons: true,
    },
    {
      title: t('economy:accounting-items-tab.table.head.insurance-provider'),
      sortDirection,
      fieldName: 'insuranceProvider',
      handleSortDirectionChange: () => {
        onApplySorting('insuranceProvider');
      },
      includeSortButtons: true,
    },
    {
      title: t('economy:accounting-items-tab.table.head.benefit-type'),
      sortDirection,
      fieldName: 'benefitType',
    },
    {
      title: t('economy:accounting-items-tab.table.head.insurance-type'),
      sortDirection,
      fieldName: 'insuranceType',
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head) => {
        return (
          <Th
            compressed
            includeSortButtons={head.includeSortButtons}
            sortDirection={head.fieldName === sortField ? sortDirection : undefined}
            key={`accounting-items-table-heads-${head.fieldName}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default AccountingItemsTableHeads;
