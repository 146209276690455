import { gql } from '@apollo/client';

export const GET_CALCULATION_LOCKS = gql`
  query GetCalculaitonLocks($employeeId: UUID!, $allowDrafts: Boolean!) {
    calculationLocks(employeeId: $employeeId, allowDrafts: $allowDrafts) {
      id
      fromPeriod
      toPeriod
      policyPglRuleName {
        id
        name
      }
      kubType
      employeeCardId
      deleted
      isDraft
    }
  }
`;
